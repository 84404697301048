import { Grid, Avatar, Typography } from "@mui/material";

import "./style.css";

import {
  ArrowUpward as ArrowUpwardIcon,
  AttachMoney,
  ShoppingCart,
  Restaurant,
  PeopleAlt,
} from "@mui/icons-material";
import { primaryColor } from "../../../../constants/colors";
import memberDashBoardStyle from "./style";

const Card = ({ assignedBatch }) => {
  console.log(assignedBatch,"nnnnsdsad")
  const classes = memberDashBoardStyle();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <div
              className={`${classes.customCard} card-style`}
              style={{ backgroundColor: primaryColor }}
            >
              <div className="main-users">
                <Grid className="avatar-container product-container">
                  <Grid item>
                    <Avatar className="avatar-style">
                      <ShoppingCart />
                    </Avatar>
                  </Grid>
                </Grid>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  className={classes.count}
                  style={{ color: "white" }}
                >
                  {assignedBatch ? assignedBatch : 0}
                </Typography>
                <Avatar
                  className={classes.avatarStyle}
                  style={{ backgroundColor: "#fff8e1" }}
                >
                  <ArrowUpwardIcon
                    fontSize="inherit"
                    style={{
                      transform: "rotate3d(1, 1, 1, 45deg)",
                      color: "#fff",
                    }}
                  />
                </Avatar>
              </div>
              <div>
                <p className={classes.title} style={{ color: "#fff" }}>
                  Assigned Batches
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Card;
