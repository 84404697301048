import React from "react";
import { TableCell } from "@mui/material";

const RestImageResolver = ({ row }) => {
  return (
    <TableCell padding="normal">
      <img
        src={row.restuarantImage ? row.restuarantImage : null}
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
        }}
      />
    </TableCell>
  );
};

export default RestImageResolver;
