import React, { useState, useEffect } from "react";
import { DialogContent, TextField, InputLabel, Grid } from "@mui/material";
import useStyles from "../../../globalStyles";
import BootstrapDialog from "../../../components/dialog";
import DialogHeader from "../../../components/dialog/dialogHeader";
import DialogFooter from "../../../components/dialog/dialogFooter";
import axios from "axios";
import FileSaver from "file-saver";
import { updateStudentById } from "../../../services/members/studentRegistartion/StudentRegistartion";
import { getAllCourses } from "../../../services/admin/courses/Courses";
import { baseUrl } from "../../../services";
import { ToWords } from "to-words";
import moment from "moment";
export default function FeesDialog({
  open,
  type,
  feesPaid,
  setFeesPaid,
  handleClose,
  dialogData,
  setIsLoading,
  handleSnackbarVal,
  handleGetAllStudentsByBatch,
  receivedBy,
  setReceivedBy,
  totalCourseFees,
  courseName,
}) {
  const classes = useStyles();

  console.log(dialogData,"asajsdjsdajhasdjhasd")
  useEffect(() => {
    if (dialogData) {
      setFeesPaid(0);
      setReceivedBy(dialogData.receivedBy);
    }
  }, [dialogData]);

  const handleBtn = () => {
    if (feesPaid < 0) {
      handleSnackbarVal(true, "error", `Amount can not be less 0`);
    } else if (receivedBy == "") {
      handleSnackbarVal(true, "error", `Name is required`);
    } else if (
      totalCourseFees <
      parseInt(feesPaid) + parseInt(dialogData.feesPaid)
    ) {
      handleSnackbarVal(
        true,
        "error",
        `Fees can not be greater than the total Fees`
      );
    } else {
      let payload = {
        feesPaid: parseInt(dialogData.feesPaid) + parseInt(feesPaid),
        receivedBy: receivedBy,
      };
      setIsLoading(true);
      updateStudentById(dialogData?._id, payload)
        .then((response) => {
          setIsLoading(false);
          if (response.status == 200) {
            handleClose();
            getAllCourses();
            handleSnackbarVal(true, "success", response.data.message);
            handleGetAllStudentsByBatch();
            const toWords = new ToWords();
            let words = toWords.convert(response?.data?.data?.feesPaid);
            const newDate = moment(new Date()).format("DD-MM-YY");
            axios
              .post(`${baseUrl}createPdf`, {
                totalFees: totalCourseFees,
                feesPaid: response?.data?.data?.feesPaid,
                discount: response?.data?.data?.discount,
                courseName: courseName,
                words: words,
                date: newDate,
                currentPaid: feesPaid,
                type: "feesPaid",
                name: response?.data?.data?.name,
              })
              .then((res) => {
                axios
                  .get(`${baseUrl}fetchPdf`, { responseType: "blob" })
                  .then((response) => {
                    const pdfBlob = new Blob([response.data], {
                      type: "application/pdf",
                    });
                    FileSaver.saveAs(pdfBlob, `${dialogData?.name}`);
                  })
                  .then((res) => {})
                  .catch(() => {
                    setIsLoading(false);
                  });

                axios
                  .post(`${baseUrl}/sendPdf`, {
                    email: dialogData.email,
                    courseName: courseName,
                    type: "feesPaid",
                    currentPaid: feesPaid,
                  })
                  .then((resultfinal) => {
                    console.log(resultfinal);
                    setIsLoading(false);
                  })
                  .catch(() => {
                    setIsLoading(false);
                  });
              })
              .catch((err) => {
                setIsLoading(false);
                handleSnackbarVal(true, "error", err?.message);
              });
          } else {
            handleSnackbarVal(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div>
      <BootstrapDialog onClose={handleClose} open={open}>
        <DialogHeader title={"Edit Fees"} handleClose={handleClose} />
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel className={classes.globalInputLabel}>
                Fees Paid
              </InputLabel>
              <TextField
                autoFocus
                placeholder="Fees Paid"
                variant="outlined"
                size="small"
                className={classes.globalInput}
                type="number"
                value={feesPaid}
                onChange={(e) => setFeesPaid(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.globalInputLabel}>
                Received By
              </InputLabel>
              <TextField
                autoFocus
                placeholder=" Received By"
                variant="outlined"
                size="small"
                className={classes.globalInput}
                type="text"
                value={receivedBy}
                onChange={(e) => setReceivedBy(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogFooter
          type={type}
          handleUpdate={handleBtn}
          handleClose={handleClose}
        />
      </BootstrapDialog>
    </div>
  );
}
