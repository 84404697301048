import React from "react";
import {
  Document,
  Page,
  View,
  StyleSheet,
  Text,
  Font,
} from "@react-pdf/renderer";
import RobotoLight from "../../../assets/fonts/Roboto-Light.ttf";
import RobotoBold from "../../../assets/fonts/Roboto-Bold.ttf";
import RobotoMedium from "../../../assets/fonts/Roboto-Medium.ttf";

// Register the fonts
Font.register({
  family: "RobotoLight",
  fonts: [{ src: RobotoLight }],
});
Font.register({
  family: "RobotoBold",
  fonts: [{ src: RobotoBold }],
});
Font.register({
  family: "RobotoMedium",
  fonts: [{ src: RobotoMedium }],
});

// Define the styles
const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    padding: "20px",
  },
  headingContainer: {
    width: "100%",
    padding: "10px 5px",
    textAlign: "center",
    border: "2px solid black",
    borderBottom: "0px solid black",
  },
  tableContainer: {
    width: "100%",
    border: "1px solid black",
    textAlign: "center",
  },
  tableHeading: {
    fontFamily: "RobotoBold",
    fontSize: "20px",
  },
  tableRow: {
    flexDirection: "row",
    width: "100%",
  },
  tableCell: {
    border: "1px solid black",
    padding: "2px",
    textAlign: "left",
    margin: 0,
  },
  nameCell: {
    width: "25%",
  },
  smallCell: {
    width: "4.69%",
  },
  textBold: {
    fontFamily: "RobotoMedium",
    fontSize: "10px",
  },
  textLight: {
    fontFamily: "RobotoLight",
    fontSize: "10px",
  },
});

// Define the PDF component
const AttendancePdf = ({ allPdfData }) => {
  const courseName = allPdfData[0].courseName.toUpperCase();
  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <View style={styles.container}>
          <View style={styles.headingContainer}>
            <Text style={styles.tableHeading}>
              ATTENDANCE SHEET OF {courseName}
            </Text>
          </View>
          <View style={styles.tableContainer}>
            <View style={{ ...styles.tableRow, height: "40px" }}>
              <View style={{ ...styles.tableCell, width: "5%" }}>
                <Text style={styles.textBold}></Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  ...styles.nameCell,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Text style={styles.textBold}>DATES</Text>
              </View>
              {Array.from({ length: 16 }).map((_, i) => (
                <View
                  key={i}
                  style={{ ...styles.tableCell, ...styles.smallCell }}
                >
                  <Text style={styles.textBold}></Text>
                </View>
              ))}
            </View>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCell, width: "5%" }}>
                <Text style={styles.textBold}>S\NO</Text>
              </View>
              <View style={{ ...styles.tableCell, ...styles.nameCell }}>
                <Text style={styles.textBold}>STUDENT NAME'S</Text>
              </View>
              {Array.from({ length: 16 }).map((_, i) => (
                <View
                  key={i}
                  style={{ ...styles.tableCell, ...styles.smallCell }}
                >
                  <Text style={styles.textBold}>{i + 1}</Text>
                </View>
              ))}
            </View>

            {allPdfData.map((data, index) => (
              <View style={styles.tableRow} key={index}>
                <View style={{ ...styles.tableCell, width: "5%" }}>
                  <Text style={styles.textLight}>{index + 1}</Text>
                </View>
                <View style={{ ...styles.tableCell, ...styles.nameCell }}>
                  <Text style={styles.textLight}>{data.name}</Text>
                </View>
                {Array.from({ length: 16 }).map((_, i) => (
                  <View
                    key={i}
                    style={{ ...styles.tableCell, ...styles.smallCell }}
                  >
                    <Text style={styles.textLight}></Text>
                  </View>
                ))}
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default AttendancePdf;
