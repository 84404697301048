import Api from "../../index"
import { endPoints,requestType } from "../../../constants/variables"


export const updateMembersProfile=(id,params)=>{
 return Api(`${endPoints.updateMembersProfile}/${id}`,params,requestType.PUT)   
}
export const deleteMember=(id)=>{
 return Api(`${endPoints.deleteMember}/${id}`,null,requestType.DELETE)   
}
export const loginMember=params=>{
 return Api(endPoints.login,params,requestType.POST)   
}