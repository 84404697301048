import React from "react";
import { Document, Page, View, StyleSheet, Text } from "@react-pdf/renderer";
import { Font } from "@react-pdf/renderer";
import RobotoLight from "../../../assets/fonts/Roboto-Light.ttf";
import RobotoBold from "../../../assets/fonts/Roboto-Bold.ttf";

// Registering fonts
Font.register({
  family: "RobotoLight",
  src: RobotoLight,
});
Font.register({
  family: "RobotoBold",
  src: RobotoBold,
});

// Styles
const styles = StyleSheet.create({
  container: {
    padding: "30px 5px",
    paddingBottom: "10px",
  },
  tableContainer: {
    border: "2px solid black",
    textAlign: "center",
    borderBottom: "1px",
  },
  mainHeadingContainer: {
    padding: "8px 0px",
    borderBottom: "2px solid black",
  },
  tableMainHeading: {
    fontFamily: "RobotoBold",
    fontSize: 20,
  },
  tableHeading: {
    fontFamily: "RobotoBold",
    fontSize: 13,
    fontWeight: 500,
  },
  tableRow: {
    flexDirection: "row",
    width: "100%",
    border: "1px solid black",
    border: "none",
    borderBottom: "1px",
  },
  tableCell: {
    border: "1px solid black",
    padding: 4,
    textAlign: "left",
    borderBottom: "none",
    borderTop: "none",
    borderRight: "none",
    fontSize: 12,
    fontWeight: 400,
  },
  footer: {
    width: "30%",
    marginTop: 20,
    padding: "5px 10px",
    fontSize: 14,
    border: "2px solid black",
  },
  signature: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid black",
    marginBottom: 8,
  },
  footerText: {},
  totalContainer: {
    marginTop: "30px",
    // padding: "5px",
    // border: "2px solid black",
  },
  totalText: {
    border: "2px solid black",
    padding: 4,
    textAlign: "left",
    borderBottom: "none",
    // borderTop: "none",
    borderRight: "none",
    fontSize: 12,
    fontWeight: 400,
  },
});

// IeltsPdf component
const IeltsPdf = ({ allPdfData, teacherShare, batchReport }) => {
  // Calculating totals

  const overAllFees = allPdfData.reduce((acc, curr) => acc + curr.totalFees, 0);
  const overAllPaid = allPdfData.reduce((acc, curr) => acc + curr.feesPaid, 0);

  const overAllDiscount = allPdfData.reduce(
    (acc, curr) => acc + curr.discount,
    0
  );
  const overAllRefund = allPdfData.reduce((acc, curr) => acc + curr.refund, 0);

  const teacherShareOnTotalFee = (overAllFees * teacherShare) / 100;

  const overAllDue = overAllFees - overAllPaid - overAllDiscount;

  const courseName = allPdfData[0].courseName.toUpperCase();

  // const calculdateTeacherShare = () =>{

  // }

  return (
    <Document>
      <Page size={[830.98, 591.28]} wrap>
        <View style={styles.container}>
          <View style={styles.tableContainer}>
            <View style={styles?.mainHeadingContainer}>
              <Text style={styles.tableMainHeading}>{courseName}</Text>
            </View>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCell, width: "4%" }}>
                <Text style={styles.tableHeading}>S.no</Text>
              </View>
              <View style={{ ...styles.tableCell, width: "20%" }}>
                <Text style={styles.tableHeading}>Name of Students</Text>
              </View>
              <View style={{ ...styles.tableCell, width: "11%" }}>
                <Text style={styles.tableHeading}>Contact No</Text>
              </View>
              <View style={{ ...styles.tableCell, width: "7%" }}>
                <Text style={styles.tableHeading}>Total Fee</Text>
              </View>
              <View style={{ ...styles.tableCell, width: "9%" }}>
                <Text style={styles.tableHeading}>Fee Received</Text>
              </View>
              <View style={{ ...styles.tableCell, width: "7%" }}>
                <Text style={styles.tableHeading}>Fee Due</Text>
              </View>
              <View style={{ ...styles.tableCell, width: "7%" }}>
                <Text style={styles.tableHeading}>Discount</Text>
              </View>
              <View style={{ ...styles.tableCell, width: "7%" }}>
                <Text style={styles.tableHeading}>Refund</Text>
              </View>
              <View style={{ ...styles.tableCell, width: "17%" }}>
                <Text style={styles.tableHeading}>Course</Text>
              </View>
              <View style={{ ...styles.tableCell, width: "14%" }}>
                <Text style={styles.tableHeading}>Discount by</Text>
              </View>
            </View>
            {allPdfData.map((data, index) => (
              <View style={styles.tableRow} key={index}>
                <View style={{ ...styles.tableCell, width: "4%" }}>
                  <Text>{index + 1}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: "20%" }}>
                  <Text>{data.name}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: "11%" }}>
                  <Text>{data.contact}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: "7%" }}>
                  <Text>{data.totalFees}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: "9%" }}>
                  <Text>{data.feesPaid}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: "7%" }}>
                  <Text>{data.totalFees - data.feesPaid - data.discount}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: "7%" }}>
                  <Text>{data.discount}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: "7%" }}>
                  <Text>{data.refund}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: "17%" }}>
                  <Text>{data.courseName}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: "14%" }}>
                  <Text>{data.discountBy}</Text>
                </View>
              </View>
            ))}
          </View>

          {/* total every thing */}
          <View style={styles.totalContainer}>
            <View style={{ ...styles.tableRow, border: "none" }}>
              <View style={{ ...styles.totalText, width: "34%" }}>
                <Text>Total</Text>
              </View>
              <View style={{ ...styles.totalText, width: "7%" }}>
                <Text>{batchReport.overAllFees}</Text>
              </View>
              <View style={{ ...styles.totalText, width: "9%" }}>
                <Text>{batchReport.overAllPaid}</Text>
              </View>
              <View style={{ ...styles.totalText, width: "7%" }}>
                <Text>{batchReport.overAllDue}</Text>
              </View>
              <View style={{ ...styles.totalText, width: "7%" }}>
                <Text>{batchReport.overAllDiscount}</Text>
              </View>
              <View style={{ ...styles.totalText, width: "36%" }}>
                <Text>{batchReport.overAllRefund}</Text>
              </View>
            </View>
          </View>
          {/* Footer */}
          <View style={styles.footer}>
            <View style={styles.signature}>
              <Text style={styles.footerText}>Teacher Share</Text>
              <Text style={styles.footerText}>
                {batchReport.teacherShareOnTotalFess}
              </Text>
            </View>
            <View style={styles.signature}>
              <Text style={styles.footerText}>Discount</Text>
              <Text style={styles.footerText}>
                {batchReport.overAllDiscount}
              </Text>
            </View>
            <View style={styles.signature}>
              <Text style={styles.footerText}>Refund</Text>
              <Text style={styles.footerText}>{batchReport.overAllRefund}</Text>
            </View>
            <View style={styles.signature}>
              <Text style={styles.footerText}>Fee Due</Text>
              <Text style={styles.footerText}>{batchReport.overAllDue}</Text>
            </View>
            <View style={styles.signature}>
              <Text style={styles.footerText}>Total Income</Text>
              <Text style={styles.footerText}>
                {batchReport.totalIncome.toFixed(2)}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default IeltsPdf;
