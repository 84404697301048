import React, { useState, useEffect } from "react";
import {
  DialogContent,
  TextField,
  InputLabel,
  Grid,
  Checkbox,
  FormControlLabel,
  Box,
} from "@mui/material";
import useStyles from "../../../../globalStyles";
import BootstrapDialog from "../../../../components/dialog";
import DialogHeader from "../../../../components/dialog/dialogHeader";
import DialogFooter from "../../../../components/dialog/dialogFooter";
import axios from "axios";
import SnackbarAlert from "../../../../components/snackbar/SnackbarAlert";
import Loader from "../../../../components/loader/index";
import moment from "moment";
import DatePicker from "react-datepicker";
import FormControl from "@mui/material/FormControl";
import { Theme, useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import "react-datepicker/dist/react-datepicker.css";
import {
  createBatch,
  updateBatchById,
} from "../../../../services/members/batch/Batch";
import {
  getAllCourses,
  getAllCoursesByMemberId,
} from "../../../../services/admin/courses/Courses";
import { fetchAllMembers } from "../../../../services/admin/members/Members";

const dummyyData = [];

export default function BatchDialog({
  open,
  type,
  inputValue,
  setInputValue,
  handleClose,
  getCategories,
  dialogData,
  setIsLoading,
  handleSnackbarVal,
  setFilterByCourse,
}) {
  const classes = useStyles();
  const userData = JSON.parse(localStorage.getItem("user"));
  const [checkBoxData, setCheckBoxData] = useState(dummyyData);
  const [allCourses, setAllCourses] = useState([]);
  const [allMembers, setAllMembers] = useState([]);
  useEffect(() => {
    getAllCoursesByMember();
    getAllMembers();
    if (dialogData) {
      setInputValue({
        assignedMember: dialogData?.assignedMember,
        courseId: dialogData?.courseId,
        totalFees: dialogData?.totalFees,
        teacherName: dialogData?.teacherName,
        batchName: dialogData?.batchName,
        teacherShare: dialogData?.teacherShare,
        instituteShare: dialogData?.instituteShare,
        startDate: moment(dialogData.startDate, "YYYY-MM-DD").toDate(),
        endDate: moment(dialogData.endDate, "YYYY-MM-DD").toDate(),
      });
    }
  }, [dialogData]);

  const handleChangeDate = (date, name) => {
    console.log(date, "Asdlkasjdasjdljk");
    setInputValue({
      ...inputValue,
      [name]: date,
    });
  };

  const getAllMembers = () => {
    fetchAllMembers(userData?._id)
      .then((res) => {
        console.log(res, "ressssss");
        let data = res.data.data ? res.data.data : {};
        console.log(data,"ALLMEMBERS")
        setAllMembers(data);
      })
      .catch((err) => {
        console.log(err, "errr");
        setIsLoading(false);
      });
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };



  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setInputValue({
      ...inputValue,
      assignedMember: typeof value === "string" ? value.split(",") : value,
    });
  };

  const handleChangeCourse = (e) => {
    let resss = allCourses.find((itemmmmm) => itemmmmm._id === e.target.value);
    setInputValue({
      ...inputValue,
      courseId: e.target.value,
      totalFees: resss.fees,
      courseName: resss.courseName,
    });
  };

  const handleChangeInput = (e) => {
    setInputValue({
      ...inputValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleBtn = async () => {
    if (inputValue.batchName == "") {
      handleSnackbarVal(true, "error", `Batch Name is required`);
    } else if (inputValue.courseId == "") {
      handleSnackbarVal(true, "error", `Select any course`);
    } else if (inputValue.totalFees == "") {
      handleSnackbarVal(true, "error", `Total fees is required`);
    } else if (inputValue.teacherShare == "") {
      handleSnackbarVal(true, "error", `Teacher share is required`);
    } else if (inputValue.instituteShare == "") {
      handleSnackbarVal(true, "error", `Institute share is required`);
    } else if (inputValue.teacherName == "") {
      handleSnackbarVal(true, "error", `Teacher name is required`);
    } else if (inputValue.assignedMember == "") {
      handleSnackbarVal(true, "error", `Assign member is required`);
    } else {
      if (type === "add") {
        let payload = {
          batchName: inputValue.batchName,
          startDate: moment(inputValue.startDate).format("YYYY-MM-DD"),
          endDate: moment(inputValue.endDate).format("YYYY-MM-DD"),
          teacherShare: inputValue?.teacherShare,
          instituteShare: inputValue?.instituteShare,
          totalFees: inputValue?.totalFees,
          courseId: inputValue?.courseId,
          courseName: inputValue?.courseName,
          teacherName: inputValue?.teacherName,
          assignedMember: inputValue?.assignedMember,
        };
        setIsLoading(true);
        createBatch(payload)
          .then((res) => {
            if (res.status === 200) {
              setInputValue({
                batchName: "",
                startDate: "",
                endDate: "",
                teacherShare: "",
                instituteShare: "",
                totalFees: "",
                courseId: "",
              });
              setIsLoading(false);
              handleSnackbarVal(true, "success", res.data.message);
              getCategories();
              handleClose();
            } else {
              setIsLoading(false);
              handleSnackbarVal(true, "error", res.data.message);
            }
          })
          .catch((error) => {
            handleClose();
            console.log(error, "asdasdsada");
            setIsLoading(false);
            handleSnackbarVal(true, "error", error?.response?.data?.message);
          });
      } else {
        let payload = {
          batchName: inputValue.batchName,
          startDate: moment(inputValue.startDate).format("YYYY-MM-DD"),
          endDate: moment(inputValue.endDate).format("YYYY-MM-DD"),
          teacherShare: inputValue?.teacherShare,
          instituteShare: inputValue?.instituteShare,
          totalFees: inputValue?.totalFees,
          teacherName: inputValue?.teacherName,
          assignedMember: inputValue?.assignedMember,
          courseName: inputValue?.courseName,
          courseId: inputValue?.courseId,
        };
        setIsLoading(true);
        updateBatchById(dialogData._id, payload)
          .then((response) => {
            if (response?.status == 200) {
              handleSnackbarVal(true, "success", response.data.message);
              setInputValue({
                batchName: "",
                startDate: "",
                endDate: "",
                teacherShare: "",
                instituteShare: "",
                totalFees: "",
                courseId: "",
              });
              setIsLoading(false);
              handleClose();
              getCategories();
            } else {
              setIsLoading(false);
              handleSnackbarVal(true, "error", response.data.message);
            }
          })
          .catch((error) => {
            handleSnackbarVal(true, "error", error?.response?.data?.message);
            setIsLoading(false);
            handleClose();
          });
      }
    }
  };

  const getAllCoursesByMember = () => {
    getAllCourses(userData?._id)
      .then((res) => {
        console.log(res, "ressssss");
        let data = res.data.data ? res.data.data : {};
        console.log(data, "data=========>>>>");
        setAllCourses(data);
        setFilterByCourse(data);
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  };

  
  const handleChangeVal = (item, ind) => {
    let tempArr = [...checkBoxData];
    tempArr[ind].selected = !tempArr[ind].selected;
    setCheckBoxData(tempArr);
  };

  console.log(inputValue, "inputValue=========>>>");
  console.log(dialogData,"DIALOGGGGGGGGGGGGG");

  return (
    <div>
      <BootstrapDialog onClose={handleClose} open={open}>
        <DialogHeader
          title={type == "add" ? "Add Batch" : "Edit Batch"}
          handleClose={handleClose}
        />
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Batch Name
              </InputLabel>
              <TextField
                autoFocus
                placeholder="Batch Name"
                variant="outlined"
                size="small"
                name="batchName"
                className={classes.globalInput}
                value={inputValue.batchName}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Select Course To Assign
              </InputLabel>
              <select
                defaultValue={inputValue?.courseId}
                value={inputValue?.courseId}
                onChange={handleChangeCourse}
                name="courseId"
                className="select-option-style"
                style={{ width: "100%", padding: "11px" }}
              >
                <option value="">Select Course</option>
                {allCourses?.map((item, ind) => {
                  return <option value={item._id}>{item?.name}</option>;
                })}
              </select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Total Fees
              </InputLabel>
              <TextField
                autoFocus
                placeholder="Total Fees"
                variant="outlined"
                size="small"
                disabled
                name="totalFees"
                type="number"
                className={classes.globalInput}
                value={inputValue.totalFees}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Teacher Share
              </InputLabel>
              <TextField
                placeholder="Teacher Share"
                variant="outlined"
                size="small"
                type="number"
                name="teacherShare"
                className={classes.globalInput}
                value={inputValue.teacherShare}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Institute Share
              </InputLabel>
              <TextField
                placeholder="Institute Share"
                variant="outlined"
                size="small"
                name="instituteShare"
                type="number"
                className={classes.globalInput}
                value={inputValue.instituteShare}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Teacher Name
              </InputLabel>
              <TextField
                placeholder="Teacher Name"
                variant="outlined"
                size="small"
                name="teacherName"
                className={classes.globalInput}
                value={inputValue.teacherName}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.globalInputLabel}>
                Assigned Members
              </InputLabel>
              <FormControl sx={{ width: "100%", height: 20 }}>
                <Select
                  multiple
                  value={inputValue?.assignedMember}
                  name="assignedMember"
                  size="small"
                  onChange={handleChange}
                  input={<OutlinedInput label="Name" />}
                  MenuProps={MenuProps}
                >
                  {allMembers?.map((item) => (
                    <MenuItem
                      key={item?._id}
                      value={item?._id}
                      style={{ fontWeight: "bold" }}
                      // style={getStyles(
                      //   item?.name,
                      //   inputValue.assignedMember,
                      //   theme
                      // )}
                    >
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Start Date
              </InputLabel>
              <DatePicker
                selected={inputValue.startDate}
                onChange={(date) => handleChangeDate(date, "startDate")}
                name="startDate"
                dateFormat="dd-MM-yyyy"
                // minDate={new Date()}
                // maxDate={new Date()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                End Date
              </InputLabel>
              <DatePicker
                selected={inputValue.endDate}
                onChange={(date) => handleChangeDate(date, "endDate")}
                // minDate={new Date()}
                name="endDate"
                dateFormat="dd-MM-yyyy"
              />
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                {checkBoxData.map((item, ind) => {
                  return (
                    <Box sx={{ width: { xs: "100%", sm: "33.3%" } }}>
                      <FormControlLabel
                        key={item.id}
                        control={<Checkbox defaultChecked size="small" />}
                        label={item.name}
                        onClick={() => handleChangeVal(item, ind)}
                      />
                    </Box>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogFooter
          type={type}
          handleCreate={handleBtn}
          handleUpdate={handleBtn}
          handleClose={handleClose}
        />
      </BootstrapDialog>
    </div>
  );
}
