import Api from "../../index"
import { endPoints,requestType } from "../../../constants/variables"


export const registerMember=params=>{
 return Api(endPoints.registerMember,params,requestType.POST)   
}
export const loginMember=params=>{
 return Api(endPoints.login,params,requestType.POST)   
}

export const forgotPassword=params=>{
    return Api(endPoints.forgotPassword,params,requestType.POST)
}

