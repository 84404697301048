import React from "react";
import {
  Document,
  Page,
  View,
  StyleSheet,
  Text,
  Font,
  Image,
  Link,
} from "@react-pdf/renderer";
//Images
import BackgroundImage from "../../../assets/images/IPD-CERTIFICATE-BG.png";
import IpdLogo from "../../../assets/images/IPD-lOGO.png";
import SignatureOne from "../../../assets/images/ipd-saima-signature.png";
import SignatureTwo from "../../../assets/images/ipd-aziz-signature.png";
//Fonts
import RobotoLight from "../../../assets/fonts/Roboto-Light.ttf";
import RobotoBold from "../../../assets/fonts/Roboto-Bold.ttf";
import RobotoMedium from "../../../assets/fonts/Roboto-Medium.ttf";
import PoppinsMedium from "../../../assets/fonts/Poppins/Poppins-Medium.ttf";
import PoppinsLight from "../../../assets/fonts/Poppins/Poppins-Light.ttf";

// Register the fonts
Font.register({
  family: "RobotoLight",
  fonts: [{ src: RobotoLight }],
});
Font.register({
  family: "RobotoBold",
  fonts: [{ src: RobotoBold }],
});
Font.register({
  family: "RobotoMedium",
  fonts: [{ src: RobotoMedium }],
});
Font.register({
  family: "PoppinsMedium",
  fonts: [{ src: PoppinsMedium }],
});
Font.register({
  family: "PoppinsLight",
  fonts: [{ src: PoppinsLight }],
});

// Define the styles
const styles = StyleSheet.create({
  page: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  content: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  backgroundImage: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // opacity: 0.5,
  },

  logoImage: {
    width: "100px",
    // height: "100%",
  },

  paddingMain: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    paddingTop: 70,
    paddingLeft: 80,
    paddingRight: 80,
  },
  miniHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "4px",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    gap: 65,
  },
  primaryHeading: {
    fontFamily: "PoppinsMedium",
    color: "#17384D",
    fontWeight: "bold",
    fontSize: "18px",
  },
  secondaryHeading: {
    fontFamily: "PoppinsLight",
    color: "#17384D",
    fontSize: "12px",
    letterSpacing: "1px",
  },
  mainHeading: {
    marginTop: "40px",
    fontFamily: "PoppinsLight",
    color: "#17384D",
    textAlign: "center",
    fontSize: "55px",
    lineHeight: "normal",
  },
  mainHeadingLight: {
    fontFamily: "PoppinsLight",
    fontSize: "12px",
    textAlign: "center",
    marginTop: "-10px",
    color: "#17384D",
    letterSpacing: "1px",
  },
  participant: {
    marginTop: "15px",
  },
  participantName: {
    marginTop: "20px",
    textAlign: "center",
    fontFamily: "PoppinsLight",
    color: "#17384D",
    fontSize: "30px",
  },
  noteLine: {
    fontFamily: "PoppinsLight",
    fontSize: "12px",
    textAlign: "center",
    width: "70%",
    margin: "auto",
    marginTop: "5px",
    color: "#17384D",
    letterSpacing: "1px",
  },
  horizontalLine: {
    backgroundColor: "#BA851C",
    height: "3px",
    borderRadius: "10px",
    width: "70%",
    margin: "auto",
  },
  footer: {
    width: "70%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "auto",
    marginTop: 25,
  },
  signOne: {
    width: "30%",
    alignItems: "center",
  },

  signTwo: {
    alignItems: "center",
    width: "30%",
  },
  signerName: {
    fontFamily: "PoppinsLight",
    fontSize: "18px",
    textAlign: "center",
    marginTop: "4px",
    color: "#17384D",
    letterSpacing: "1px",
  },
  designation: {
    fontFamily: "PoppinsLight",
    fontSize: "12px",
    textAlign: "center",
    color: "#BA851C",
    marginTop: "-5px",
  },
  bottomLink: {
    position: "absolute",
    bottom: 5,
    right: 0,
    left: 0,
    zIndex: 999,
  },
  linkText: {
    fontFamily: "PoppinsLight",
    fontSize: "12px",
    textAlign: "center",
    color: "#17384D",
    letterSpacing: "1px",
    textDecoration:"none"
  },
});

// Define the PDF component
const IpdCertificate = () => {
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.content}>
          <Image src={BackgroundImage} style={styles.backgroundImage} />
          <View style={styles.paddingMain}>
            <View style={styles.miniHeader}>
              <Text style={{ ...styles.primaryHeading, fontSize: "12px" }}>
                Credential Id:
              </Text>
              <Text style={styles.secondaryHeading}>98736476</Text>
            </View>
            <View style={styles.header}>
              {/* content goes here */}
              <View>
                <Image src={IpdLogo} style={styles.logoImage} />
              </View>
              <View style={{ alignItems: "center" }}>
                <Text style={styles.primaryHeading}>
                  Aga Khan Education Board for Garden
                </Text>
                <Text style={styles.primaryHeading}>
                  Institute of Professional Development
                </Text>
              </View>
              <View>
                <Text style={{ fontFamily: "RobotoBold", fontSize: 24 }}></Text>
              </View>
            </View>
            <View>
              <Text style={styles.mainHeading}>CERTIFICATE</Text>
              <Text style={styles.mainHeadingLight}>
                This is to certify that
              </Text>
            </View>
            <View style={styles.participant}>
              <Text style={styles.participantName}>
                Muhammad Masood Huraira
              </Text>
              <View style={styles.horizontalLine}></View>
              <Text style={styles.noteLine}>
                has successfully completed the course name Course/workshop,
                conducted by AKEB’G-IPD on the start date to end date.
              </Text>
            </View>
            <View style={styles.footer}>
              <View style={styles.signOne}>
                <Image src={SignatureOne} style={{ width: "100px" }} />
                <View
                  style={{ ...styles.horizontalLine, width: "100%" }}
                ></View>
                <Text style={styles.signerName}>Saima Shiraz</Text>
                <Text style={styles.designation}>Chairperson AKEB'G</Text>
              </View>
              <View style={styles.signTwo}>
                <Image src={SignatureTwo} style={{ width: "100px" }} />
                <View
                  style={{ ...styles.horizontalLine, width: "100%" }}
                ></View>
                <Text style={styles.signerName}>Aziz Aftab</Text>
                <Text style={styles.designation}>Convenor DAC</Text>
              </View>
            </View>
            <View style={styles.bottomLink}>
              <Link src="https://akeb-ipd.pk" style={styles.linkText}>
                Verify at www.akeb-ipd.pk/verification
              </Link>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default IpdCertificate;
