import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./containers/auth/login";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AdminRoutes, MemberRoutes, ObRoutes } from "./routes/allRoutes";
import AppProvider from "./context";
import ForgotPassword from "./containers/auth/forgotPassword";
import { ProtectedLayout } from "./components/protectedLayout";
import StudentsDetailPage from "./containers/app/studentsDetailPage";
import OnlineRegistrationForm from "./containers/app/registrationForm";

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: ["Lato", "sans-serif", ,].join(","),
    },
    button: {
      fontFamily: ["Lato", "sans-serif"].join(","),
    },
  });
  const isUserAuthenticate = () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      return userData;
    } else {
      return null;
    }
  };
  const isAuthenticated = isUserAuthenticate();

  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <Routes>
          <Route path="/registration" element={<OnlineRegistrationForm />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/" element={<ProtectedLayout />}>
            {isAuthenticated?.role === "Admin" ? (
              AdminRoutes.map(({ component: Component, path, exact, id }) => {
                return (
                  <Route
                    key={id}
                    path={path}
                    exact={exact}
                    element={Component}
                  />
                );
              })
            ) : isAuthenticated?.role === "ob" ? (
              ObRoutes.map(({ component: Component, path, exact, id }) => {
                return (
                  <Route
                    key={id}
                    path={path}
                    exact={exact}
                    element={Component}
                  />
                );
              })
            ) : isAuthenticated?.role === "Member" ? (
              MemberRoutes.map(({ component: Component, path, exact, id }) => {
                return (
                  <Route
                    key={id}
                    path={path}
                    exact={exact}
                    element={Component}
                  />
                );
              })
            ) : (
              <Route path="*" element={<Navigate to="/login" replace />} />
            )}
            <Route
              path="/studentsDetails/:batchId"
              element={<StudentsDetailPage />}
            />
          </Route>
        </Routes>
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
