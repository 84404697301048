export const requestType = {
  POST: "post",
  GET: "get",
  PUT: "put",
  DELETE: "delete",
};

export const endPoints = {
  ///Admin Authentication
  registerAdmin: "registerAdmin",
  loginAdmin: "loginAdmin",

  //  Admin Dashboard API
  dashboardData: "DashboardData",

  ///Fetch members
  fetchMember: "fetchMember",
  updateMemberStatus: "changeMemberStatus",

  //Member Authentication
  registerMember: "registerMember",
  changePassword: "changePassword",
  login: "login",
  forgotPassword: "forgotPassword",

  //Member Profile
  updateMembersProfile: "updateMembersProfile",

  //member dashboard
  memberDashboard: "memberDashboard",

  deleteMember: "deleteMember",
  //courses
  createCourse: "createCourse",
  getAllCourses: "getAllCourses",
  updateCourseById: "updateCourseById",
  deleteCourse: "deleteCourse",
  getAllCoursesByMemberId: "getAllCoursesByMemberId",
  getTotalCourses: "getTotalCourses",

  //Create Batch
  getAllBatchesByMember: "getAllBatchesByMemberId",
  createBatch: "createBatch",
  getAllBatches: "getAllBatches",
  updateBatchById: "updateBatchById",
  deleteBatch: "deleteBatch",
  getAllBatchesByCourseId: "getAllBatchesByCourseId",
  getAllStudentsByBatchId: "getAllStudentsByBatchId",
  updateClassStatus: "updateClassStatus",

  //Student Registration

  registerStudent: "registerStudent",
  getAllStudents: "getAllStudents",
  getAllStudentsByRegistrarId: "getAllStudentsByRegistrarId",
  updateStudentById: "updateStudentById",
  deleteStudent: "deleteStudent",
  getAllStudentsByCourseId: "getAllStudentsByCourseId",
  registerOnlineStudent: "registerOnlineStudent",
  getOnlineStudent: "getOnlineStudent",
  exportStudents:"exportStudents",

  //Send Email
  sendEmail: "sendEmail",
  saveImage: "saveImage",

  // Report Api
  generateReport: "generateReport",

  //Super Admin
  login: "Login",
  fetchAllAdmin: "fetchAllAdmin",
  changeAdminStatus: "changeAdminStatus",
  deleteAdmin: "deleteAdmin",
  createRequest: "createRequest",
  getAllRequests: "getAllRequests",
  getAllRequestsByAdmin: "getAllRequestsByAdmin",
  updateRequestById: "updateRequestById",
  deleteRequest: "deleteRequest",

  //ob apis

  registerOb: "registerOb",
  fetchAllOb: "fetchAllOb",
  updateOb: "updateOb",
  deleteOb: "deleteOb",

  //expire
  checkTokenExpire: "checkTokenExpire",

  getLimitedStudents: "getLimitedStudents",
};
