import Api from "../../index";
import { endPoints, requestType } from "../../../constants/variables";

export const getTotalStudents = (params) => {
  return Api(endPoints.getAllStudents, null, requestType.GET);
};

export const getAllStudentsByPage = (params, search) => {
  console.log(search, "ASAsasaSAsaSA");
  return Api(
    `${endPoints.getLimitedStudents}?page=${params}`,
    search,
    requestType.POST
  );
};

export const registerOnlineStudent = (params) => {
  return Api(endPoints.registerOnlineStudent, params, requestType.POST);
};

export const getOnlineStudent = (params) => {
  return Api(endPoints.getOnlineStudent, null, requestType.GET);
};

export const exportStudents = (params) => {
  return Api(endPoints.exportStudents, params, requestType.POST);
};
