import { useState, useEffect } from "react";
import { Grid, TextField, Typography, Button, Container } from "@mui/material";
import DynamicTable from "../../../../components/dynamicTable";
import NavigationDrawer from "../../../../components/navigationDrawer";
import DeleteHelper from "../../../../components/alert/deleteHelper";
import Loader from "../../../../components/loader";
import SnackbarAlert from "../../../../components/snackbar/SnackbarAlert";
import ObAddDialog from "./obAddDialog";
import { deleteOb, fetchAllOb } from "../../../../services/ob/obAuthentication/obAuthentication";
import ObStyle from "./styles";

const headerData = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: "Action",
  },
];


const diplayRows = ["id", "name", "email","actions"];

const ObRegistration = () => {
  //   const user = JSON.parse(localStorage.getItem("user"));
  const classes = ObStyle();
  const [type, setType] = useState("add");
  const [openDialog, setOpenDialog] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [dialogData, setDialgData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const [inputValue, setInputValue] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  useEffect(() => {
    getAllMembers();
  }, []);

  const getAllMembers = () => {
    setIsLoading(true);
    fetchAllOb()
      .then((res) => {
        console.log(res, "resssssst");
        let data = res.data.data ? res.data.data : {};
        setIsLoading(false);
        setRowsData(data);
        setFilterData(data);
      })
      .catch((err) => {
        console.log(err, "errr");
        setIsLoading(false);
      });
  };

  const handleOnfilter = (event) => {
    const filterItem = rowsData.filter((item) => {
      return item.name.toLowerCase().includes(event.target.value.toLowerCase());
    });
    setFilterData(filterItem);
  };

  const openAddDialog = () => {
    setOpenDialog(true);
    setType("add");
  };

  const openEditDialog = (row) => {
    setOpenDialog(true);
    setDialgData(row);
    setType("edit");
  };

  const handleClose = () => {
    setDialgData(null);
    setOpenDialog(false);
    setInputValue({
      name: "",
      email: "",
      password: "",
    });
  };

    const handleDelete = (val) => {
      DeleteHelper(true, val._id, deleteOb, getAllMembers);
    };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  return (
    <NavigationDrawer>
      <Loader isloading={isLoading} />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />

      <Grid container alignItems="center">
        <Grid item xs={12} md={3}>
          <Typography style={{ fontSize: "22px", fontWeight: "600" }}>
            OB Registrations
          </Typography>
        </Grid>
      </Grid>
      <div style={{ textAlign: "right", margin: "10px 0" }}>
        <TextField
          placeholder="Search"
          size="small"
          onChange={handleOnfilter}
        />
        <Button
          variant="contained"
          size="small"
          disableRipple
          className={classes.loginBtn}
          onClick={openAddDialog}
        >
          <Typography className={classes.loginBtnText}>Add New</Typography>
        </Button>
      </div>

      {/* >> Table */}
      <DynamicTable
        headerData={headerData}
        bodyRows={filterData}
        displayData={diplayRows}
        openEditDialog={openEditDialog}
        showDelete={true}
        showEdit={true}
        type={type}
        handleDelete={handleDelete}
        // handleEditStatus={handleEditStatus}
      />

      <ObAddDialog
        open={openDialog}
        setOpenDialog={setOpenDialog}
        type={type}
        inputValue={inputValue}
        setInputValue={setInputValue}
        handleClose={handleClose}
        getAllMembers={getAllMembers}
        dialogData={dialogData}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        handleSnackbarVal={handleSnackbarVal}
      />
    </NavigationDrawer>
  );
};

export default ObRegistration;
