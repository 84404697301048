import { useState, useEffect } from "react";
import { Grid, TextField, Typography, Button, Container } from "@mui/material";
import DynamicTable from "../../../../components/dynamicTable";
import NavigationDrawer from "../../../../components/navigationDrawer";
import MemberAddEditDialog from "./memberAddEditDialog";
import DeleteHelper from "../../../../components/alert/deleteHelper";
import Loader from "../../../../components/loader";
import SnackbarAlert from "../../../../components/snackbar/SnackbarAlert";
import {
  fetchAllMembers,
  updateMemberStatus,
} from "../../../../services/admin/members/Members";
import { deleteMember } from "../../../../services/members/memberProfile/MemberProfile";
import MemberRegistrationStyle from "./style";

const headerData = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "contact",
    numeric: false,
    disablePadding: true,
    label: "Contact",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];


const diplayRows = ["id", "name", "email", "contact", "isApproved", "actions"];

const MembersRegistrations = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const classes = MemberRegistrationStyle();
  const [type, setType] = useState("add");
  const [openDialog, setOpenDialog] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [dialogData, setDialgData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const [inputValue, setInputValue] = useState({
    name: "",
    email: "",
    contact: "",
    password: "",
    fatherName: "",
    profession: "",
    gender: "",
    dob: "",
    education: "",
    otherInsitutions: [],
    age: "",
    image: "",
    address: "",
    cnic:""
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const getAllMembers = () => {
    setIsLoading(true);
    fetchAllMembers()
      .then((res) => {
        console.log(res, "resssssst");
        let data = res.data.data ? res.data.data : {};
        setIsLoading(false);
        setRowsData(data);
        setFilterData(data);
      })
      .catch((err) => {
        console.log(err, "errr");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllMembers();
  }, []);

  const handleOnfilter = (event) => {
    const filterItem = rowsData.filter((item) => {
      return item.name.toLowerCase().includes(event.target.value.toLowerCase());
    });
    setFilterData(filterItem)
  };

  const openAddDialog = () => {
    setOpenDialog(true);
    setType("add");
  };

  const openEditDialog = (row) => {
    setOpenDialog(true);
    setDialgData(row);
    setType("edit");
  };

  const handleClose = () => {
    setDialgData(null);
    setOpenDialog(false);
    setInputValue({
      name: "",
    });
  };

  const handleDelete = (val) => {
    DeleteHelper(true, val._id, deleteMember, getAllMembers);
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const handleEditStatus = (e, row) => {
    let paylaod = {
      status: !row.isApproved,
      id: row._id,
    };
    setIsLoading(true);
    updateMemberStatus(paylaod)
      .then((res) => {
        setIsLoading(false);
        if (res.status == 200) {
          getAllMembers();
          handleSnackbarVal(
            true,
            "success",
            "Member status updated successfully"
          );
        } else {
          setIsLoading(false);
          handleSnackbarVal(true, "error", res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <NavigationDrawer>
      <Loader isloading={isLoading} />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />

      <Grid container alignItems="center">
        <Grid item xs={12} md={3}>
          <Typography style={{ fontSize: "22px", fontWeight: "600" }}>
            Members Registrations
          </Typography>
        </Grid>
      </Grid>
      <div style={{ textAlign: "right", margin: "10px 0" }}>
        <TextField
          placeholder="Search"
          size="small"
          onChange={handleOnfilter}
        />
        <Button
          variant="contained"
          size="small"
          disableRipple
          className={classes.loginBtn}
          onClick={openAddDialog}
        >
          <Typography className={classes.loginBtnText}>Add New</Typography>
        </Button>
      </div>

      {/* >> Table */}
      <DynamicTable
        headerData={headerData}
        bodyRows={filterData}
        displayData={diplayRows}
        showDelete={true}
        showEdit={true}
        handleEditStatus={handleEditStatus}
        handleDelete={handleDelete}
        openEditDialog={openEditDialog}
        type={type}
      />

      <MemberAddEditDialog
        open={openDialog}
        setOpenDialog={setOpenDialog}
        type={type}
        inputValue={inputValue}
        setInputValue={setInputValue}
        handleClose={handleClose}
        getAllMembers={getAllMembers}
        dialogData={dialogData}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        handleSnackbarVal={handleSnackbarVal}
      />
    </NavigationDrawer>
  );
};

export default MembersRegistrations;
