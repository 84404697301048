import React, { useState, useEffect } from "react";
import { DialogContent, TextField, InputLabel, Grid } from "@mui/material";
import useStyles from "../../../../globalStyles";
import BootstrapDialog from "../../../../components/dialog";
import DialogHeader from "../../../../components/dialog/dialogHeader";
import DialogFooter from "../../../../components/dialog/dialogFooter";
import {
  registerStudent,
  updateStudentById,
} from "../../../../services/members/studentRegistartion/StudentRegistartion";
import { getAllCourses } from "../../../../services/admin/courses/Courses";
import { getAllBatchesByCourseId } from "../../../../services/members/batch/Batch";
export default function EditStudentDialog({
  open,
  handleClose,
  getAllStudentsData,
  dialogData,
  inputValue,
  setInputValue,
  handleSnackbarVal,
  setIsLoading,
}) {
  const classes = useStyles();
  const userData = JSON.parse(localStorage.getItem("user"));
  const [allCourses, setAllCourses] = useState([]);
  const [allBatches, setAllBatches] = useState([]);
  const validNumber = /^03\d{9}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    getAllCoursesByMember();
    if (dialogData) {
      setInputValue({
        name: dialogData?.name,
        email: dialogData?.email,
        contact: dialogData?.contact,
        courseId: dialogData?.courseId,
        age: dialogData?.age,
        batchId: dialogData?.batchId,
      });
      getAllBatchesByCourse(dialogData?.courseId);
    }
  }, [dialogData]);

  const handleChangeInput = (e) => {
    setInputValue({
      ...inputValue,
      [e.target.name]: e.target.value,
    });
    if (e.target.name == "courseId") {
      getAllBatchesByCourse(e.target.value);
    }
  };

  console.log(dialogData, "inputValueinputValueinputValue");

  const handleBtn = async () => {
    if (inputValue.name == "") {
      handleSnackbarVal(true, "error", `Student name is required`);
    } else if (inputValue.email == "") {
      handleSnackbarVal(true, "error", `Student email is required`);
    } else if (!emailRegex.test(inputValue.email)) {
      handleSnackbarVal(true, "error", `Invalid Email Format`);
    } else if (inputValue.contact == "") {
      handleSnackbarVal(true, "error", `Student contact is required`);
    } else if (!validNumber.test(inputValue.contact)) {
      handleSnackbarVal(true, "error", `Invalid contact number`);
    } else if (inputValue.age == "") {
      handleSnackbarVal(true, "error", `Student age is required`);
    } else if (inputValue.courseId == "") {
      handleSnackbarVal(true, "error", `Please select course`);
    } else if (inputValue.batchId == "") {
      handleSnackbarVal(true, "error", `Please select batch`);
    } else {
      let payload = {
        name: inputValue?.name,
        email: inputValue?.email,
        contact: inputValue?.contact,
        registrarId: userData._id,
        courseId: inputValue?.courseId,
        batchId: inputValue?.batchId,
        adminId: userData?.adminId,
        age: inputValue?.age,
      };
      setIsLoading(true);
      updateStudentById(dialogData._id, payload)
        .then((res) => {
          setInputValue({
            name: "",
            email: "",
            contact: "",
            courseId: "",
            batchId: "",
            age: "",
          });
          setIsLoading(false);
          handleSnackbarVal(true, "success", res.data.message);
          getAllStudentsData();
          handleClose();
        })
        .catch((error) => {
          handleClose();
          setIsLoading(false);
          handleSnackbarVal(true, "error", error?.response?.data?.message);
        });
    }
  };

  const getAllCoursesByMember = () => {
    getAllCourses(userData?.adminId)
      .then((res) => {
        let data = res.data.data ? res.data.data : {};
        setAllCourses(data);
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  };

  const getAllBatchesByCourse = (id) => {
    getAllBatchesByCourseId(id)
      .then((res) => {
        let data = res.data.data ? res.data.data.slice().reverse() : [];
        setAllBatches(data);
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  };

  return (
    <div>
      <BootstrapDialog onClose={handleClose} open={open}>
        <DialogHeader title={"Edit Registration"} handleClose={handleClose} />
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>Name</InputLabel>
              <TextField
                autoFocus
                placeholder="Name"
                variant="outlined"
                size="small"
                name="name"
                className={classes.globalInput}
                value={inputValue.name}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Email
              </InputLabel>
              <TextField
                placeholder="Email"
                variant="outlined"
                size="small"
                name="email"
                className={classes.globalInput}
                value={inputValue.email}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Contact
              </InputLabel>
              <TextField
                placeholder="Contact"
                variant="outlined"
                size="small"
                name="contact"
                type="number"
                className={classes.globalInput}
                value={inputValue.contact}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>Age</InputLabel>
              <TextField
                placeholder="Age"
                variant="outlined"
                size="small"
                type="number"
                name="age"
                className={classes.globalInput}
                value={inputValue.age}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Select Course
              </InputLabel>
              <select
                value={inputValue.courseId}
                onChange={handleChangeInput}
                name="courseId"
                className="select-option-style"
                style={{ width: "100%", padding: "11px" }}
              >
                <option value="">Select Course</option>
                {allCourses?.map((item, ind) => {
                  return <option value={item?._id}>{item?.name}</option>;
                })}
              </select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Select Batch
              </InputLabel>
              <select
                value={inputValue.batchId}
                onChange={handleChangeInput}
                name="batchId"
                className="select-option-style"
                style={{ width: "100%", padding: "11px" }}
              >
                <option value="">Select Batch</option>
                {allBatches?.map((item, ind) => {
                  return <option value={item?._id}>{item?.batchName}</option>;
                })}
              </select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogFooter
          type={"edit"}
          handleCreate={handleBtn}
          handleUpdate={handleBtn}
          handleClose={handleClose}
        />
      </BootstrapDialog>
    </div>
  );
}
