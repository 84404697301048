import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Loader from "../../../../components/loader";
import NavigationDrawer from "../../../../components/navigationDrawer";
import SnackbarAlert from "../../../../components/snackbar/SnackbarAlert";
import TableForStudents from "../../../../components/tableForStudents";
import { getAllCourses } from "../../../../services/admin/courses/Courses";
import { getAllStudentsByPage } from "../../../../services/admin/students/student";
import StudentRegistrationStyle from "./style";
import StudentRegisteredBymemberDial from "./studentRegisteredByMemberDial";
import { getAllStudentsByRegistrarId } from "../../../../services/members/studentRegistartion/StudentRegistartion";

const headerData = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "age",
    numeric: false,
    disablePadding: true,
    label: "Age",
  },
  {
    id: "contact",
    numeric: false,
    disablePadding: true,
    label: "Contact",
  },
  {
    id: "courseName",
    numeric: false,
    disablePadding: true,
    label: "Course",
  },
  {
    id: "batchName",
    numeric: false,
    disablePadding: true,
    label: "Batch",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: "Action",
  },
];

const diplayRows = [
  "id",
  "name",
  "email",
  "age",
  "contact",
  "courseName",
  "batchName",
  "actions",
];

const StudentRegistartion = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const classes = StudentRegistrationStyle();
  const [type, setType] = useState("add");
  const [openDialog, setOpenDialog] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [dialogData, setDialgData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurretPage] = useState(0);
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("name");
  const [paginationtozero, setPaginationToZero] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const [inputValue, setInputValue] = useState({
    name: "",
    email: "",
    contact: "",
    course: "",
    courseName: "",
    courseId: "",
    batchName: "",
    batchId: "",
    discount: "",
    age: "",
  });

  const [allCourses, setAllCourses] = useState([]);

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  useEffect(() => {
    getAllCoursesByAdmin();
  }, []);

  useEffect(() => {
    if (search !== "") {
      getAllStudentsDataByPage(currentPage, {
        searchBody: search,
        filterType: searchBy,
      });
    } else {
      getAllStudentsDataByPage(currentPage, null);
    }
  }, [currentPage]);

  const getAllStudentsDataByPage = (data, search) => {
    setIsLoading(true);
    getAllStudentsByRegistrarId(user?._id, data, search)
      .then((res) => {
        let data = res.data.data.reverse();
        setIsLoading(false);
        setFilterData(data);
      })
      .catch((err) => {
        console.log(err, "errr");
        setIsLoading(false);
      });
  };

  const getAllCoursesByAdmin = () => {
    getAllCourses(user?._id)
      .then((res) => {
        let data = res.data.data ? res.data.data : {};
        setAllCourses(data);
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  };

  const openAddDialog = () => {
    setOpenDialog(true);
    setType("add");
  };

  const openEditDialog = (row) => {
    setOpenDialog(true);
    setDialgData(row);
    setType("edit");
  };

  const handleClose = () => {
    setDialgData(null);
    setOpenDialog(false);
    setInputValue({
      name: "",
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const handleSearchhhhhh = () => {
    if (search) {
      getAllStudentsDataByPage(0, { searchBody: search, filterType: searchBy });
      localStorage.setItem("currentOrderPage", 0);
      setPaginationToZero(true);
    } else {
      handleSnackbarVal(true, "error", "Search value is required");
    }
  };

  const handleCancelSearch = () => {
    setSearch("");
    getAllStudentsDataByPage(0, null);
  };

  return (
    <NavigationDrawer>
      <Loader isloading={isLoading} />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />

      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Typography
            style={{
              fontSize: "22px",
              fontWeight: "600",
              marginBottom: "10px",
            }}
          >
            Students Registrations
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1} justifyContent={"flex-end"}>
        {searchBy !== "courseId" && (
          <Grid item xs={12} sm={4} display={"flex"}>
            <TextField
              type="text"
              variant="outlined"
              placeholder="search"
              fullWidth
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {search && (
                      <IconButton
                        onClick={handleCancelSearch}
                        variant={"contained"}
                      >
                        <CancelIcon htmlColor={"red"} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <Box height={"42px"}>
              <Button
                variant="contained"
                size="small"
                disableRipple
                className={classes.loginBtn}
                onClick={handleSearchhhhhh}
              >
                <Typography className={classes.loginBtnText}>Search</Typography>
              </Button>
            </Box>
          </Grid>
        )}
        {searchBy === "courseId" && (
          <Grid item xs={12} sm={4} display={"flex"}>
            <FormControl fullWidth size="small" sx={{ marginBottom: "20px" }}>
              <Select
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Select"
              >
                <MenuItem value={""}>None</MenuItem>
                {allCourses?.map((item, ind) => {
                  return <MenuItem value={item?._id}>{item?.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <Box height={"42px"}>
              <Button
                variant="contained"
                size="small"
                disableRipple
                className={classes.loginBtn}
                onClick={handleSearchhhhhh}
              >
                <Typography className={classes.loginBtnText}>Search</Typography>
              </Button>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth size="small" sx={{ marginBottom: "20px" }}>
            <Select
              value={searchBy}
              defaultValue=""
              placeholder="Select"
              onChange={(e) => setSearchBy(e.target.value)}
            >
              <MenuItem value={"name"}>Name</MenuItem>
              <MenuItem value={"email"}>Email</MenuItem>
              <MenuItem value={"age"}>Age</MenuItem>
              <MenuItem value={"contact"}>Contact</MenuItem>
              <MenuItem value={"courseId"}>Course</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box textAlign="right" mb={3}>
        <Button
          variant="contained"
          size="small"
          disableRipple
          className={classes.loginBtn}
          onClick={openAddDialog}
        >
          <Typography className={classes.loginBtnText}>Add New</Typography>
        </Button>
      </Box>

      {/* >> Paginated Table */}

      <TableForStudents
        headerData={headerData}
        bodyRows={filterData}
        displayData={diplayRows}
        setCurrentPage={setCurretPage}
        showEdit={true}
        openEditDialog={openEditDialog}
        paginationtozero={paginationtozero}
        setPaginationToZero={setPaginationToZero}
      />

      <StudentRegisteredBymemberDial
        open={openDialog}
        setOpenDialog={setOpenDialog}
        type={type}
        inputValue={inputValue}
        setInputValue={setInputValue}
        handleClose={handleClose}
        getCategories={getAllStudentsDataByPage}
        dialogData={dialogData}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        handleSnackbarVal={handleSnackbarVal}
        currentPage={currentPage}
      />
    </NavigationDrawer>
  );
};

export default StudentRegistartion;
