import { makeStyles } from "@mui/styles";
import { primaryColor, secondaryColor } from "../../../../constants/colors";

const StudentRegistrationStyle = makeStyles((theme) => ({
  loginBtn: {
    "&.MuiButton-root": {
      backgroundColor: primaryColor,
      width: "10%",
      padding: "10px",
      // marginLeft: "20px",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: secondaryColor,
      },
    },
  },
  loginBtnText: {
    fontSize: "12px !important",
    textTransform: "none",
    fontWeight: "500 !important", 
  },
}));

export default StudentRegistrationStyle;
