import React, { useState, useEffect } from "react";
import { DialogContent, TextField, InputLabel, Grid } from "@mui/material";
import useStyles from "../../../globalStyles";
import BootstrapDialog from "../../../components/dialog";
import DialogHeader from "../../../components/dialog/dialogHeader";
import DialogFooter from "../../../components/dialog/dialogFooter";
import {
  registerStudent,
  updateStudentById,
} from "../../../services/members/studentRegistartion/StudentRegistartion";
import { getAllCourses } from "../../../services/admin/courses/Courses";

export default function EditStudentDisCount({
  open,
  type,
  discount,
  setDiscount,
  handleClose,
  getCategories,
  dialogData,
  setIsLoading,
  handleSnackbarVal,
  handleGetAllStudentsByBatch,
  discountBy,
  setDiscountBy,
  receivedBy,
  setReceivedBy,
}) {
  const classes = useStyles();
  const [cal, setCal] = useState(0);
  useEffect(() => {
    if (dialogData) {
      setDiscount(dialogData?.discount);
      setDiscountBy(dialogData?.discountBy);
      setReceivedBy(dialogData?.receivedBy);
      //calculate the remaining amount left after feespaid amount from course fees total = coursefees - feespaid
      let discountCalculate =
        parseInt(dialogData?.courseFess) - parseInt(dialogData?.feesPaid);
      setCal(discountCalculate);
    }
  }, [dialogData]);

  const handleBtn = async () => {
    if (discount < 0) {
      handleSnackbarVal(true, "error", `Discount can not be less than 0`);
    } else if (parseInt(discount) > cal) {
      handleSnackbarVal(
        true,
        "error",
        "Discount amount can not be greater than fees amount"
      );
    } else if (discountBy == "") {
      handleSnackbarVal(true, "error", "Name is required");
    } else {
      let payload = {
        discount: discount,
        discountBy: discountBy,
        receivedBy: receivedBy,
      };
      setIsLoading(true);
      updateStudentById(dialogData?._id, payload)
        .then((response) => {
          setIsLoading(false);
          if (response.status == 200) {
            handleClose();
            getAllCourses();
            handleSnackbarVal(true, "success", response.data.message);
            handleGetAllStudentsByBatch();
          } else {
            handleSnackbarVal(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          handleSnackbarVal(true, "error", error?.message);
        });
    }
  };

  return (
    <div>
      <BootstrapDialog onClose={handleClose} open={open}>
        <DialogHeader title={"Edit Discount"} handleClose={handleClose} />
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel className={classes.globalInputLabel}>
                Discount
              </InputLabel>
              <TextField
                type="number"
                placeholder="Discount"
                variant="outlined"
                size="small"
                value={discount}
                className={classes.globalInput}
                onChange={(e) => setDiscount(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.globalInputLabel}>
                Discound By
              </InputLabel>
              <TextField
                type="text"
                placeholder="Discount by"
                variant="outlined"
                size="small"
                value={discountBy}
                className={classes.globalInput}
                onChange={(e) => setDiscountBy(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.globalInputLabel}>
                Fees Received By
              </InputLabel>
              <TextField
                type="text"
                placeholder="Received By"
                variant="outlined"
                size="small"
                value={receivedBy}
                className={classes.globalInput}
                onChange={(e) => setReceivedBy(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogFooter
          type={type}
          handleUpdate={handleBtn}
          handleClose={handleClose}
        />
      </BootstrapDialog>
    </div>
  );
}
