import Api from "../../index";
import { endPoints, requestType } from "../../../constants/variables";

export const registerOb = (params) => {
  return Api(endPoints.registerOb, params, requestType.POST);
};

export const fetchAllOb = (params) => {
  return Api(endPoints.fetchAllOb, null, requestType.GET);
};

export const updateOb = (id, params) => {
  return Api(`${endPoints.updateOb}/${id}`, params, requestType.PUT);
};

export const deleteOb = (id) => {
  return Api(`${endPoints.deleteOb}/${id}`, null, requestType.DELETE);
};
