import React, { useState } from "react";
import { Button, TextField, CircularProgress, Typography } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { primaryColor, secondaryColor } from "../../../constants/colors";
import useStyles from "../../../globalStyles";
import AuthBackground from "../../../components/authBackground";
import Swal from "sweetalert2";
import { useAuth } from "../../../components/hooks/useAuth";
import { LoginUser } from "../../../services/authentication/authentication";
const Login = () => {
  const classes = useStyles();
  const { login, user } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async () => {
    let data = {
      email,
      password,
    };
    if (email == "" || password == "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Email & password are required",
        confirmButtonColor: "red",
      });
    } else {
      setIsLoading(true);
      LoginUser(data)
        .then((response) => {
          if (response.data.status == "error") {
            setIsLoading(false);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response?.data?.message,
              confirmButtonColor: "red",
            });
          } else {
            let newObj = {
              ...response.data.data.userDetails,
            };
            console.log(response.data.data, "response.data.data");
            setIsLoading(false);
            localStorage.setItem("user_token", response.data.data.token);
            setEmail("");
            setPassword("");
            localStorage.setItem("user", JSON.stringify(newObj));
            login(response.data.data.userDetails);
            window.location.pathname = "/";
          }
        })
        .catch((err) => {
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Something went wrong while log in",
            confirmButtonColor: "red",
          });
        });
    }
  };

  if (user) {
    return <Navigate to="/" replace />;
  }

  return (
    <AuthBackground>
      <div className={classes.paper}>
        <Typography style={{ fontSize: "25px", fontWeight: "600" }}>
          Welcome!
        </Typography>
        <Typography style={{ fontWeight: "500" }}>
          Log in to continue
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          id="email"
          placeholder="Email"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          className={classes.globalInput}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={(e) => (e.key == "Enter" ? handleLogin() : null)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          name="password"
          value={password}
          placeholder="Password"
          type="password"
          id="password"
          className={classes.globalInput}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={(e) => (e.key == "Enter" ? handleLogin() : null)}
        />
        {/* <Grid item xs={12} sm={12}>
          <InputLabel className={classes.globalInputLabel}>
            Select User Type
          </InputLabel>
          <select
            value={type}
            onChange={(e) => setType(e.target.value)}
            name='type'
            className='select-option-style'
            style={{ width: '100%', padding: '11px' }}
          >
            <option value=''>Select User Type</option>
            <option value='admin'>Admin</option>;
            <option value='member'>Member</option>;
          </select>
        </Grid> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px 0",
          }}
        >
          <Typography
            onClick={() => navigate("/forgotPassword")}
            style={{ color: secondaryColor, cursor: "pointer" }}
          >
            Forgot Password
          </Typography>
          {/* <Typography
            onClick={() => navigate('/signup')}
            style={{ cursor: 'pointer', colors: secondaryColor }}
          >
            Don't have an account ? Sign up
          </Typography> */}
        </div>
        <div style={{ textAlign: "center", width: "100%" }}>
          {isLoading ? (
            <CircularProgress style={{ color: primaryColor }} />
          ) : (
            <Button
              variant="contained"
              className={classes.loginBtn}
              size="small"
              onClick={handleLogin}
              disableRipple
            >
              <Typography className={classes.loginBtnText}>Sign In</Typography>
            </Button>
          )}
        </div>
      </div>
    </AuthBackground>
  );
};

export default Login;
