import { useState, useEffect } from "react";
import { Grid, TextField, Typography, Button, Container } from "@mui/material";
import DynamicTable from "../../../../components/dynamicTable";
import NavigationDrawer from "../../../../components/navigationDrawer";
import CoursesDialog from "./coursesDialog";
import DeleteHelper from "./../../../../components/alert/deleteHelper";
import Loader from "../../../../components/loader";
import SnackbarAlert from "../../../../components/snackbar/SnackbarAlert";
import {
  getAllCourses,
  deleteCourse,
} from "../../../../services/admin/courses/Courses";
import courseStyle from "./style";

const Courses = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const classes = courseStyle();
  const [type, setType] = useState("add");
  const [openDialog, setOpenDialog] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [dialogData, setDialgData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const [inputValue, setInputValue] = useState({
    name: "",
    fees: "",
    courseType: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const headerData = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "ID",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Course Name",
    },
    {
      id: "fees",
      numeric: false,
      disablePadding: true,
      label: "Fees",
    },
    {
      id: "Course Type",
      numeric: false,
      disablePadding: true,
      label: "Course Type",
    },
  ].concat(
    user?.role !== "ob"
      ? [
          {
            id: "actions",
            numeric: false,
            disablePadding: true,
            label: "Action",
          },
        ]
      : []
  );

  //unable actions when login by role ob

  const diplayRows = ["id", "name", "fees", "courseType"].concat(
    user?.role !== "ob" ? ["actions"] : []
  );

  const getAllCoursesByAdmin = () => {
    setIsLoading(true);
    getAllCourses()
      .then((res) => {
        console.log(res.data.data, "ressssss");
        let data = res.data.data ? res.data.data : {};
        console.log(data, "data=========>>>>");
        setIsLoading(false);
        setRowsData(data);
        setFilterData(data);
      })
      .catch((err) => {
        console.log(err, "errr");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllCoursesByAdmin();
  }, []);

  const handleOnfilter = (event) => {
    const filterItem = rowsData.filter((item) => {
      return item.name.toLowerCase().includes(event.target.value.toLowerCase());
    });
    console.log(filterItem, "filterItem");
    setFilterData(filterItem);
  };

  const openAddDialog = () => {
    setOpenDialog(true);
    setType("add");
  };

  const openEditDialog = (row) => {
    setOpenDialog(true);
    setDialgData(row);
    setType("edit");
  };

  const handleClose = () => {
    setDialgData(null);
    setOpenDialog(false);
    setInputValue({
      name: "",
      fees: "",
      courseType: "",
    });
  };

  const handleDelete = (val) => {
    DeleteHelper(true, val._id, deleteCourse, getAllCoursesByAdmin);
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  return (
    <NavigationDrawer>
      <Loader isloading={isLoading} />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />

      <Grid container alignItems="center">
        <Grid item xs={12} md={2}>
          <Typography style={{ fontSize: "22px", fontWeight: "600" }}>
            Courses
          </Typography>
        </Grid>
      </Grid>
      <div style={{ textAlign: "right", margin: "10px 0" }}>
        <TextField
          placeholder="Search"
          size="small"
          onChange={handleOnfilter}
        />
        {user.role !== "ob" ? (
          <Button
            variant="contained"
            size="small"
            disableRipple
            className={classes.loginBtn}
            onClick={openAddDialog}
          >
            <Typography className={classes.loginBtnText}>Add New</Typography>
          </Button>
        ) : null}
      </div>

      {/* >> Table */}
      <DynamicTable
        headerData={headerData}
        bodyRows={filterData}
        displayData={diplayRows}
        showDelete={true}
        showEdit={true}
        handleDelete={handleDelete}
        openEditDialog={openEditDialog}
        type={type}
      />

      <CoursesDialog
        open={openDialog}
        setOpenDialog={setOpenDialog}
        type={type}
        inputValue={inputValue}
        setInputValue={setInputValue}
        handleClose={handleClose}
        getCategories={getAllCoursesByAdmin}
        dialogData={dialogData}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        handleSnackbarVal={handleSnackbarVal}
      />
    </NavigationDrawer>
  );
};

export default Courses;
