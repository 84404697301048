import { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Container,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import DynamicTable from "../../../../components/dynamicTable";
import NavigationDrawer from "../../../../components/navigationDrawer";
import DeleteHelper from "./../../../../components/alert/deleteHelper";
import Loader from "../../../../components/loader";
import SnackbarAlert from "../../../../components/snackbar/SnackbarAlert";
import {
  deleteBatch,
  getAllAsignedBatchByUserId,
} from "../../../../services/members/batch/Batch";
import { useNavigate } from "react-router-dom";
import AssignedBatchEditDialog from "./assignedbatchAddEditDialog";
import {
  getAllCourses,
  getAllCoursesByMemberId,
} from "../../../../services/admin/courses/Courses";

const headerData = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "batchName",
    numeric: false,
    disablePadding: true,
    label: "Batch Name",
  },
  {
    id: "courseName",
    numeric: false,
    disablePadding: true,
    label: "CourseName",
  },
  {
    id: "startDate",
    numeric: false,
    disablePadding: true,
    label: "Start Date",
  },
  {
    id: "endDate",
    numeric: false,
    disablePadding: true,
    label: "End Date",
  },
  {
    id: "batchStatus",
    numeric: false,
    disablePadding: true,
    label: "Batch Status",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];

const diplayRows = [
  "id",
  "batchName",
  "courseName",
  "startDate",
  "endDate",
  "classStatus",
  "actions",
];

const AssignedBatches = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  console.log("dfsdsfsd", user);
  const navigate = useNavigate();
  const [type, setType] = useState("add");
  const [openDialog, setOpenDialog] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [dialogData, setDialgData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [allCourses, setAllCourses] = useState("All");
  const [assignedCourses, setAssignedCoursess] = useState([]);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const [inputValue, setInputValue] = useState({
    totalFees: "",
    teacherShare: "",
    instituteShare: "",
    startDate: new Date(),
    endDate: new Date(),
    batchName: "",
    courseId: "",
    courseName: "",
    teacherName: "",
    assignedMember: [],
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  useEffect(() => {
    getAllBatchesByMember();
    // getAllCoursesByMember();
  }, []);

  const getAllBatchesByMember = () => {
    setIsLoading(true);
    console.log(user._id, "sdfuid8weuijdueu");

    let tempArr = [];
    getAllAsignedBatchByUserId(user._id)
      .then((res) => {
        setIsLoading(false);
        console.log(res.data.data, "hffdsfsdfsdfsdfsfdfsdfsdf");
        let courseArr = [];
        res.data.data.map((item) => {
          tempArr.push({
            ...item.batch,
            courseName: item.courseName,
          });
          courseArr.push(item.courseName);
        });

        setRowsData(tempArr);
        setFilterData(tempArr);
        setAssignedCoursess([...new Set(courseArr)]);
        console.log(tempArr, "asaSASASAsaSASA");
      })
      .catch((err) => {
        console.log(err, "errr");
        setIsLoading(false);
      });
  };

  // const getAllCoursesByMember = () => {
  //   getAllCoursesByMemberId(user?._id)
  //     .then((res) => {
  //       console.log(res, "ressssss");
  //       let data = res.data.data ? res.data.data : {};
  //       console.log(data, "resssssssssssssssssssssssssss");
  //       // setAllCourses(data);
  //       setFilterByCourse(data);
  //     })
  //     .catch((err) => {
  //       console.log(err, "errr");
  //     });
  // };

  const openAddDialog = () => {
    setOpenDialog(true);
    setType("add");
  };

  const openEditDialog = (row) => {
    setOpenDialog(true);
    setDialgData(row);
    setType("edit");
  };

  const handleClose = () => {
    setOpenDialog(false);
    setDialgData(null);
    setInputValue({
      totalFees: "",
      teacherShare: "",
      instituteShare: "",
      startDate: new Date(),
      endDate: new Date(),
      teacherName: "",
      assignedMember: [],
    });
  };

  const handleDelete = (val) => {
    DeleteHelper(true, val._id, deleteBatch, getAllBatchesByMember);
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const navigateToDetail = (row) => {
    console.log(row, "rwoweorworoworeworwerosewor");

    navigate(`/studentsDetails/${row._id}`, { state: row });
  };

  console.log(inputValue, "inputValueinputValueinputValueinputValue");

  const filterByCourseName = (e) => {
    setAllCourses(e.target.value);
    if (e.target.value == "All") {
      setFilterData(rowsData);
    } else {
      console.log(e.target.value, "CHANNNNNNGEEEEE");
      const filterCourse = rowsData.filter((itemmm) =>
        itemmm.courseName.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFilterData(filterCourse);
    }
  };

  const handleOnfilter = (event) => {
    if (allCourses == "All") {
      const filterItem = rowsData.filter((item) => {
        return item.batchName
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
      setFilterData(filterItem);
    } else {
      console.log(event.target.value, "Dasdasdasdasdasdasd");
      if (event.target.value == "") {
        const filterItem = rowsData.filter((item) => {
          return item.courseName === allCourses;
        });
        setFilterData(filterItem);
      } else {
        const filterItem = filterData.filter((item) => {
          return item.batchName
            .toLowerCase()
            .includes(event.target.value.toLowerCase());
        });
        setFilterData(filterItem);
      }
    }
  };

  return (
    <NavigationDrawer>
      <Loader isloading={isLoading} />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />

      <Grid
        container
        spacing={1}
        // alignItems="center"
        mb={3}
      >
        <Grid item xs={12} sm={6}>
          <Typography style={{ fontSize: "22px", fontWeight: "600" }}>
            Assigned Batches
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={allCourses}
              onChange={filterByCourseName}
              size="small"
            >
              <MenuItem value="All">All</MenuItem>
              {assignedCourses.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            placeholder="Search by Batch "
            size="small"
            onChange={handleOnfilter}
            fullWidth
            // value={}
          />
        </Grid>
      </Grid>
      {/* >> Table */}
      <DynamicTable
        headerData={headerData}
        bodyRows={filterData}
        displayData={diplayRows}
        showDelete={false}
        showEdit={false}
        showView={true}
        navigateToDetail={navigateToDetail}
        handleDelete={handleDelete}
        openEditDialog={openEditDialog}
        type={type}
      />

      {/*<AssignedBatchEditDialog
        open={openDialog}
        setOpenDialog={setOpenDialog}
        type={type}
        inputValue={inputValue}
        setInputValue={setInputValue}
        handleClose={handleClose}
        getCategories={getAllBatchesByMember}
        dialogData={dialogData}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        handleSnackbarVal={handleSnackbarVal}
    />*/}
    </NavigationDrawer>
  );
};

export default AssignedBatches;
