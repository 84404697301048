import React, { useEffect, useState } from "react";
import NavigationDrawer from "../../../../components/navigationDrawer";
import { Box, Container, Grid, Typography } from "@mui/material";
import SnackbarAlert from "../../../../components/snackbar/SnackbarAlert";
import Loader from "../../../../components/loader";
import memberDashBoardStyle from "./style";
import Card from "./card";
import { getDashBoardDataByid } from "../../../../services/members/dashboard/dashboard";

const MemberDashBoard = () => {
  const classes = memberDashBoardStyle();
  const userData = JSON.parse(localStorage.getItem("user"));
  const [isLoading, setIsLoading] = useState(false);
  const [assignedBatch, setAssignedBatch] = useState("");
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });
  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const getDashboardData = () => {
    setIsLoading(true);
    getDashBoardDataByid(userData?._id)
      .then((res) => {
        console.log(res, "MEMBERDASHBOARDRES");
        setIsLoading(false);
        setAssignedBatch(res?.data?.total);
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
        console.log(err, "GETMEMBERDASHBOARERROR");
      });
  };

  return (
    <NavigationDrawer>
      <Loader isloading={isLoading} />

      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />

      <Container maxWidth="lg">
        <Grid container alignItems="center" mt={4}>
          <Grid item xs={12} md={2} mb={2}>
            <Typography style={{ fontSize: "30px", fontWeight: "600" }}>
              Profile
            </Typography>
          </Grid>
        </Grid>

        <Card assignedBatch={assignedBatch} />

        <Grid container spacing={3} mt={3}>
          <Grid item xs={12} sm={4}>
            <Box className={classes.profileCard}>
              <Typography fontSize="15px">Name:</Typography>
              <Typography
                fontSize="18px"
                fontWeight="600"
                textTransform="capitalize"
                mt={1}
              >
                {userData?.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className={classes.profileCard}>
              <Typography fontSize="15px">Father Name:</Typography>
              <Typography
                fontSize="18px"
                fontWeight="600"
                textTransform="capitalize"
                mt={1}
              >
                {userData?.fatherName}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className={classes.profileCard}>
              <Typography fontSize="15px">Email:</Typography>
              <Typography fontSize="18px" fontWeight="600" mt={1}>
                {userData?.email}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className={classes.profileCard}>
              <Typography fontSize="15px">Contact:</Typography>
              <Typography
                fontSize="18px"
                fontWeight="600"
                textTransform="capitalize"
                mt={1}
              >
                {userData?.contact}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className={classes.profileCard}>
              <Typography fontSize="15px">Education:</Typography>
              <Typography
                fontSize="18px"
                fontWeight="600"
                textTransform="capitalize"
                mt={1}
              >
                {userData?.education}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className={classes.profileCard}>
              <Typography fontSize="15px">Address:</Typography>
              <Typography
                fontSize="18px"
                fontWeight="600"
                textTransform="capitalize"
                mt={1}
              >
                {userData?.address}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className={classes.profileCard}>
              <Typography fontSize="15px">Gender:</Typography>
              <Typography
                fontSize="18px"
                fontWeight="600"
                textTransform="capitalize"
                mt={1}
              >
                {userData?.gender}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className={classes.profileCard}>
              <Typography fontSize="15px">Date of Birth:</Typography>
              <Typography
                fontSize="18px"
                fontWeight="600"
                textTransform="capitalize"
                mt={1}
              >
                {userData?.dob}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className={classes.profileCard}>
              <Typography fontSize="15px">Cnic No:</Typography>
              <Typography
                fontSize="18px"
                fontWeight="600"
                textTransform="capitalize"
                mt={1}
              >
                {userData?.cnicNo}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </NavigationDrawer>
  );
};

export default MemberDashBoard;
