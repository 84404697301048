import React, { useState, useEffect } from "react";
import NavigationDrawer from "../../../../components/navigationDrawer";
import Cards from "./cards";
import Loader from "../../../../components/loader";
import SnackbarAlert from "../../../../components/snackbar/SnackbarAlert";
import { getAdminDashboardStats } from "./../../../../services/admin/dashboard/Dashboard";

const AdminDashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState({});
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    let params = {};
    getData(params);
  }, []);

  const getData = (params) => {
    setIsLoading(true);
    getAdminDashboardStats()
      .then((res) => {
        setIsLoading(false);
        console.log(res, "resssssssssss");
        setDashboardData(res.data.data);
      })
      .catch((err) => {
        console.log(err, "ERRORRRR HAIII");
        setIsLoading(false);
        handleSnackbarVal(true, "error", "Network Error");
      });
  };

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  return (
    <NavigationDrawer>
      <Loader isloading={isLoading} />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />

      <Cards dashboardData={dashboardData} />
    </NavigationDrawer>
  );
};

export default AdminDashboard;
