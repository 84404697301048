import React, { useState } from "react";
import {
  Document,
  Page,
  View,
  Image,
  StyleSheet,
  Text,
} from "@react-pdf/renderer";

import { Font } from "@react-pdf/renderer";
import RobotoLight from "../../../assets/fonts/Roboto-Light.ttf";
import RobotoBold from "../../../assets/fonts/Roboto-Bold.ttf";

Font.register({
  family: "RobotoLight",
  fonts: [
    { src: RobotoLight }, // font-style: normal, font-weight: normal
  ],
});
Font.register({
  family: "RobotoBold",
  fonts: [
    { src: RobotoBold }, // font-style: normal, font-weight: normal
  ],
});

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    padding: "30px",
  },
  tableContainer: {
    width: "100%",
    border: "2px solid black",
    textAlign: "center",
    borderBottom: "1px",
  },
  tableHeading: {
    fontFamily: "RobotoBold",
    fontSize: "30px",
  },
  footer: {
    width: "30%",
    position: "absolute",
    bottom: "30px",
    left: "30px",
  },
  signature: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
});

const AllStudentPdf = ({ allPdfData, teacherShare }) => {
  const courseName = allPdfData[0].courseName.toUpperCase();
  return (
    <Document>
      <Page size={[830.98, 591.28]}>
        <View style={styles.container}>
          <View style={styles.tableContainer}>
            <Text style={styles.tableHeading}>{courseName} </Text>
            <View
              style={{
                flexDirection: "row",
                marginTop: "10px",
                width: "100%",
                border: "2px solid black",
                borderLeft: "none",
                borderRight: "none",
              }}
            >
              <View
                style={{
                  width: "5%",
                  border: "2px solid black",
                  padding: "1px",
                  border: "none",
                }}
              >
                <Text style={{ fontFamily: "RobotoBold", fontSize: "12px" }}>
                  S.no
                </Text>
              </View>
              <View
                style={{
                  width: "25%",
                  border: "2px solid black",
                  padding: "1px",
                  textAlign: "center",
                  borderRight: "none",
                  borderBottom: "none",
                  borderTop: "none",
                }}
              >
                <Text style={{ fontFamily: "RobotoBold", fontSize: "12px" }}>
                  Name of Students
                </Text>
              </View>
              <View
                style={{
                  width: "10%",
                  border: "2px solid black",
                  padding: "1px",
                  textAlign: "center",
                  borderRight: "none",
                  borderBottom: "none",
                  borderTop: "none",
                }}
              >
                <Text style={{ fontFamily: "RobotoBold", fontSize: "12px" }}>
                  Contact No
                </Text>
              </View>
              <View
                style={{
                  width: "60%",
                  border: "2px solid black",
                  padding: "1px",
                  textAlign: "center",
                  borderBottom: "none",
                  borderTop: "none",
                  borderRight: "none",
                }}
              >
                <Text style={{ fontFamily: "RobotoBold", fontSize: "12px" }}>
                  Remarks
                </Text>
              </View>
            </View>
            {allPdfData.map((data, index) => {
              return (
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    border: "2px solid black",
                    border: "none",
                    borderBottom: "1px",
                  }}
                  key={index}
                >
                  <View
                    style={{
                      width: "5%",
                      border: "2px solid black",
                      padding: "1px",
                      border: "none",
                    }}
                  >
                    <Text
                      style={{ fontFamily: "RobotoBold", fontSize: "10px" }}
                    >
                      {index + 1}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "25%",
                      border: "2px solid black",
                      padding: "1px",
                      paddingLeft: "3px",
                      textAlign: "left",
                      borderRight: "none",
                      borderBottom: "none",
                      borderTop: "none",
                    }}
                  >
                    <Text
                      style={{ fontFamily: "RobotoLight", fontSize: "10px" }}
                    >
                      {data.name}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "10%",
                      border: "2px solid black",
                      padding: "1px",
                      paddingLeft: "3px",
                      textAlign: "left",
                      borderRight: "none",
                      borderBottom: "none",
                      borderTop: "none",
                    }}
                  >
                    <Text
                      style={{ fontFamily: "RobotoLight", fontSize: "10px" }}
                    >
                      {data.contact}
                    </Text>
                  </View>

                  <View
                    style={{
                      width: "60%",
                      border: "2px solid black",
                      padding: "1px",
                      paddingRight: "5px",
                      textAlign: "right",
                      borderRight: "none",
                      borderBottom: "none",
                      borderTop: "none",
                      borderRight: "none",
                    }}
                  >
                    <Text
                      style={{ fontFamily: "RobotoLight", fontSize: "10px" }}
                    ></Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default AllStudentPdf;
