import { Grid, Avatar, Typography } from "@mui/material";
import useStyles from "./style";
import "./style.css";

import {
  ArrowUpward as ArrowUpwardIcon,
  AttachMoney,
  Share as ShareIcon,
} from "@mui/icons-material";
import { primaryColor } from "../../../../constants/colors";

const ReportCards = (props) => {
  const classes = useStyles();

  return (
    <Grid container mb={3} spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <div
              className={`${classes.customCard} card-style`}
              style={{ backgroundColor: primaryColor }}
            >
              <div className="main-users">
                <Grid className="avatar-container product-container">
                  <Grid item>
                    <Avatar className="avatar-style">
                      <AttachMoney />
                    </Avatar>
                  </Grid>
                </Grid>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  className={classes.count}
                  style={{ color: "white" }}
                >
                  {props.totalReceivedAmount?props.totalReceivedAmount:0}
                </Typography>
                <Avatar
                  className={classes.avatarStyle}
                  style={{ backgroundColor: "#fff8e1" }}
                >
                  <ArrowUpwardIcon
                    fontSize="inherit"
                    style={{
                      transform: "rotate3d(1, 1, 1, 45deg)",
                      color: "#fff",
                    }}
                  />
                </Avatar>
              </div>
              <div>
                <p className={classes.title} style={{ color: "#fff" }}>
                  Total Received Amount
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div
              className={`${classes.customCard} card-style`}
              style={{ backgroundColor: "rgb(30, 136, 229)" }}
            >
              <div className="container-two">
                <Grid
                  container
                  className="avatar-container avatar-container-two"
                >
                  <Grid item>
                    <Avatar className="avatar-style">
                      <AttachMoney />
                    </Avatar>
                  </Grid>
                </Grid>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography className={classes.count}>
                  {props?.teacherShare ? props.teacherShare : 0}
                </Typography>
                <Avatar className={classes.avatarStyle}>
                  <ArrowUpwardIcon
                    fontSize="inherit"
                    style={{ transform: "rotate3d(1, 1, 1, 45deg)" }}
                  />
                </Avatar>
              </div>
              <div>
                <p className={classes.title} style={{ color: "#fff" }}>
                  Total Teacher Share
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div
              className={`${classes.customCard} card-style`}
              style={{ backgroundColor: "#BD4291" }}
            >
              <div className="main-users">
                <Grid className="avatar-container product-container">
                  <Grid item>
                    <Avatar className="avatar-style">
                      <ShareIcon />
                    </Avatar>
                  </Grid>
                </Grid>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  className={classes.count}
                  style={{ color: "white" }}
                >
                  {props?.ipdShare ? props?.ipdShare : 0}
                </Typography>
                <Avatar
                  className={classes.avatarStyle}
                  style={{ backgroundColor: "#fff8e1" }}
                >
                  <ArrowUpwardIcon
                    fontSize="inherit"
                    style={{
                      transform: "rotate3d(1, 1, 1, 45deg)",
                      color: "#fff",
                    }}
                  />
                </Avatar>
              </div>
              <div>
                <p className={classes.title} style={{ color: "#fff" }}>
                  IPD Share
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div
              className={`${classes.customCard} card-style`}
              style={{ backgroundColor: "#BD4291" }}
            >
              <div className="main-users">
                <Grid className="avatar-container product-container">
                  <Grid item>
                    <Avatar className="avatar-style">
                      <ShareIcon />
                    </Avatar>
                  </Grid>
                </Grid>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  className={classes.count}
                  style={{ color: "white" }}
                >
                  {props?.totalFees ? props.totalFees : 0}
                </Typography>
                <Avatar
                  className={classes.avatarStyle}
                  style={{ backgroundColor: "#fff8e1" }}
                >
                  <ArrowUpwardIcon
                    fontSize="inherit"
                    style={{
                      transform: "rotate3d(1, 1, 1, 45deg)",
                      color: "#fff",
                    }}
                  />
                </Avatar>
              </div>
              <div>
                <p className={classes.title} style={{ color: "#fff" }}>
                  Total Fees
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div
              className={`${classes.customCard} card-style`}
              style={{ backgroundColor: "rgb(30, 136, 229)" }}
            >
              <div className="container-two">
                <Grid
                  container
                  className="avatar-container avatar-container-two"
                >
                  <Grid item>
                    <Avatar className="avatar-style">
                      {/* <Restaurant /> */}
                    </Avatar>
                  </Grid>
                </Grid>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography className={classes.count}>
                  {props?.teacherSharePer ? props.teacherSharePer : 0}
                </Typography>
                <Avatar className={classes.avatarStyle}>
                  <ArrowUpwardIcon
                    fontSize="inherit"
                    style={{ transform: "rotate3d(1, 1, 1, 45deg)" }}
                  />
                </Avatar>
              </div>
              <div>
                <p
                  className={classes.title}
                  style={{ color: "rgb(144, 202, 249)" }}
                >
                  Teacher Share (%)
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div
              className={`${classes.customCard} card-style`}
              style={{ backgroundColor: "rgb(30, 136, 229)" }}
            >
              <div className="container-two">
                <Grid
                  container
                  className="avatar-container avatar-container-two"
                >
                  <Grid item>
                    <Avatar className="avatar-style">
                      {/* <Restaurant /> */}
                    </Avatar>
                  </Grid>
                </Grid>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography className={classes.count}>
                  {props?.ipdSharePer ? props.ipdSharePer : 0}
                </Typography>
                <Avatar className={classes.avatarStyle}>
                  <ArrowUpwardIcon
                    fontSize="inherit"
                    style={{ transform: "rotate3d(1, 1, 1, 45deg)" }}
                  />
                </Avatar>
              </div>
              <div>
                <p
                  className={classes.title}
                  style={{ color: "rgb(144, 202, 249)" }}
                >
                  IPD Share (%)
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportCards;
