import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";

import NavigationDrawer from "../../../../components/navigationDrawer";
import Loader from "../../../../components/loader";
import SnackbarAlert from "../../../../components/snackbar/SnackbarAlert";
import emailStyle from "./style";
import BootstrapDialog from "../../../../components/dialog";
import { getAllCourses } from "../../../../services/admin/courses/Courses";
import { getAllBatchesByCourseId } from "../../../../services/members/batch/Batch";
import { saveImage, sendEmail } from "../../../../services/admin/email/Email";
import axios from "axios";

const SendEmail = () => {
  const hiddenUploadInput = useRef(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [allCourses, setAllCourses] = useState([]);
  const [allBatches, setAllBatches] = useState([]);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const [inputValues, setInputValue] = useState({
    subject: "",
    content: "",
    course: "",
    batch: "",
    image: null,
  });
  const classes = emailStyle();

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  useEffect(() => {
    getAllCoursesByMember();
  }, []);

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const handleOnChange = (e) => {
    console.log("called");
    const { name, value } = e.target;
    Promise.all(setInputValue({ ...inputValues, [name]: value }));
    if (e.target.name == "course" && e.target.value !== "All") {
      handlegetAllBatchesByCourse(e.target.value);
    }
  };

  const handleClick = () => {
    hiddenUploadInput.current.click();
  };

  const handleOnImageChange = (e) => {
    const imgData = {
      imgFile: e.target.files[0],
      imgUrl: URL.createObjectURL(e.target.files[0]),
    };
    handleImage(e);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getAllCoursesByMember = () => {
    getAllCourses(user?._id)
      .then((res) => {
        console.log(res, "ressssss");
        let data = res.data.data ? res.data.data : {};
        setAllCourses(data);
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  };

  const handlegetAllBatchesByCourse = (id) => {
    getAllBatchesByCourseId(id)
      .then((res) => {
        console.log(res, "Newnressssss");
        let data = res.data.data ? res.data.data : {};
        setAllBatches(data);
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  };

  const handleImage = async (e) => {
    setIsLoading(true);
    const form = new FormData();
    form.append("image", e.target.files[0]);
    try {
      let res = await axios.post(
        "https://sirldigital.com/uploadService.php",
        form
      );
      if (res) {
        setIsLoading(false);
        setInputValue({
          ...inputValues,
          image: res?.data.url,
        });
        // let data = { imageLink: res?.data.url };
        // saveImage(data)
        //   .then((res) => {
        //     console.log(res?.data, "responseeeee");
        //   })
        //   .catch((error) => {
        //     console.log(error, "erroorr");
        //   });
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleSendEmail = () => {
    if (inputValues.course == "") {
      handleSnackbarVal(true, "error", `Please select Course`);
    } else if (inputValues.batch == "" && inputValues.course !== "All") {
      handleSnackbarVal(true, "error", `Please select batch`);
    } else if (inputValues.content == "") {
      handleSnackbarVal(true, "error", `Please fill content section`);
    } else if (inputValues.subject == "") {
      handleSnackbarVal(true, "error", `Please fill subject section`);
    } else {
      let payload = {
        subject: inputValues?.subject,
        content: inputValues?.content,
        batch: inputValues?.batch,
        type: inputValues?.course,
        imageLink: inputValues?.image,
      };
      console.log(payload, "payloadpayloadpayload");
      // return;
      setIsLoading(true);
      sendEmail(payload)
        .then((response) => {
          setIsLoading(false);
          if (response?.data?.status == "ok") {
            handleSnackbarVal(true, "success", response?.data?.message);
            setInputValue({
              subject: "",
              content: "",
              course: "",
              batch: "",
              image: null,
            });
          } else {
            handleSnackbarVal(true, "error", response?.data?.message);
          }
        })
        .catch((error) => {
          console.log(error, "erooooore");
          setIsLoading(false);
        });
    }
  };

  return (
    <NavigationDrawer>
      <Loader isloading={isLoading} />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Box sx={{ width: "70%", margin: "50px 0 0 30px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small">
              <FormHelperText
                sx={{
                  marginLeft: "0px !important",
                  fontSize: "16px !important",
                }}
              >
                Courses
              </FormHelperText>
              <Select
                value={inputValues.course}
                onChange={handleOnChange}
                defaultValue="default"
                name="course"
              >
                {allCourses.map((item, ind) => {
                  return <MenuItem value={item?._id}>{item?.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          {inputValues.course !== "All" && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small">
                <FormHelperText
                  sx={{
                    marginLeft: "0px !important",
                    fontSize: "16px !important",
                  }}
                >
                  Batch
                </FormHelperText>
                <Select
                  value={inputValues.batch}
                  onChange={handleOnChange}
                  defaultValue="default"
                  name="batch"
                >
                  {allBatches.map((item, ind) => {
                    return (
                      <MenuItem value={item?._id}>{item?.batchName}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Box>
      <TextField
        variant="outlined"
        placeholder="subject"
        size="small"
        className={classes.subjectField}
        value={inputValues.subject}
        onChange={handleOnChange}
        name="subject"
      />
      <TextField
        variant="outlined"
        placeholder="content"
        multiline
        rows={5}
        className={classes.subjectField}
        value={inputValues.content}
        onChange={handleOnChange}
        name="content"
      />
      <Box>
        <Button
          variant="conatined"
          size="small"
          className={classes.imgBtn}
          onClick={handleClick}
        >
          Select Image
        </Button>
        {console.log(
          inputValues.image?.imgData?.imgUrl,
          "heyeyeyeyeyeyeyyeyeyyeyeyeyey"
        )}
        {inputValues.image !== null && (
          <Button
            onClick={handleClickOpen}
            variant="outlined"
            sx={{ margin: "25px 0 0 25px" }}
          >
            view Imge
          </Button>
        )}

        <input
          accept="image/*"
          ref={hiddenUploadInput}
          type={"file"}
          onChange={handleOnImageChange}
          style={{ display: "none" }}
          value={""}
        />
      </Box>
      {/* <TextField
        variant="outlined"
        placeholder="to"
        size="small"
        className={classes.subjectField}
      /> */}
      <Box>
        <Button
          variant="conatined"
          size="small"
          className={classes.imgBtn}
          onClick={() => handleSendEmail()}
        >
          Send Email
          {/* <Typography className={classes.imgBtnText}>select Image</Typography> */}
        </Button>
      </Box>

      <BootstrapDialog
        className={classes.styleDialog}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <img src={inputValues.image} height="100%" width="100%" alt={"pic"} />
        <Button onClick={handleClose}>Close</Button>
      </BootstrapDialog>
    </NavigationDrawer>
  );
};

export default SendEmail;
