import React, { useState } from "react";
import {
  Button,
  TextField,
  CircularProgress,
  Typography,
  Modal,
  Backdrop,
  Fade,
  Box,
  Grid,
} from "@mui/material";
// import Logo from "../../assets/logo.png";
import useStyles from "../../../globalStyles";
import { primaryColor, secondaryColor } from "../../../constants/colors";
import AuthBackground from "../../../components/authBackground";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import ReactCodeInput from "react-code-input";
import "./style.css";
import axios from "axios";
// import { sendCode, forgotPassword } from '../../../services/restaurant';
import Loader from "../../../components/loader";
import SnackbarAlert from "../../../components/snackbar/SnackbarAlert";
import { forgotPassword } from "../../../services/members/memberAuthentication/MemberAuth";

const ForgotPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState({
    name: "",
    email: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleForgot = () => {
    if (inputValue.name == "") {
      handleSnackbarVal(true, "error", "Name is required");
    } else if (inputValue.email == "") {
      handleSnackbarVal(true, "error", "Email is required");
    } else if (inputValue.newPassword == "") {
      handleSnackbarVal(true, "error", "New Password is required");
    } else if (inputValue.newPassword !== inputValue.confirmPassword) {
      handleSnackbarVal(true, "error", "Password is not match");
    } else {
      let paylaod = {
        name: inputValue.name,
        email: inputValue.email,
        newPassword: inputValue.newPassword,
      };
      setIsLoading(true);
      forgotPassword(paylaod)
        .then((ress) => {
          console.log(ress, "sarfsrewfokmeduscyhbenwms");
          setIsLoading(false);
          if (ress?.data?.status == "ok") {
            handleSnackbarVal(true, "success", ress.data.message);
            navigate("/login");
            setInputValue({
              name: "",
              email: "",
              newPassword: "",
            });
          } else {
            handleSnackbarVal(true, "error", ress.data.message);
          }
        })
        .catch((err) => {
          console.log(err, "ererrwerwerewrwerrw");
          setIsLoading(false);
          handleSnackbarVal(true, "errorr", err.message);
        });
    }
  };

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  return (
    <>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <AuthBackground>
        <div className={classes.paper}>
          <Typography style={{ fontSize: "25px", fontWeight: "600" }}>
            Forgot Password ?
          </Typography>
          <Typography style={{ fontWeight: "500" }}>
            We are sending a code to your email
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                type="text"
                placeholder="Name"
                variant="outlined"
                margin="normal"
                required={true}
                autoComplete="name"
                autoFocus
                value={inputValue.name}
                name="name"
                className={classes.globalInput}
                onChange={handleOnChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="email"
                variant="outlined"
                margin="normal"
                required={true}
                placeholder="Email"
                name="email"
                autoComplete="email"
                autoFocus
                className={classes.globalInput}
                onChange={handleOnChange}
                value={inputValue.email}
                onKeyPress={(e) => (e.key == "Enter" ? handleForgot() : null)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                placeholder="New password"
                variant="outlined"
                margin="normal"
                required={true}
                autoFocus
                className={classes.globalInput}
                autoComplete="newPassword"
                value={inputValue.newPassword}
                name="newPassword"
                onChange={handleOnChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                placeholder="confirm password"
                variant="outlined"
                margin="normal"
                required={true}
                autoFocus
                className={classes.globalInput}
                autoComplete="confirmPassword"
                value={inputValue.confirmPassword}
                name="confirmPassword"
                onChange={handleOnChange}
              />
            </Grid>
          </Grid>

          <div
            style={{ margin: "10px 0", display: "flex", alignItems: "center" }}
          >
            <ArrowBack
              style={{
                color: secondaryColor,
                fontSize: "18px",
                cursor: "pointer",
              }}
            />
            <Typography
              onClick={() => navigate("/login")}
              style={{ color: secondaryColor, cursor: "pointer" }}
            >
              Back to login
            </Typography>
          </div>
          <div style={{ textAlign: "center", width: "100%" }}>
            {isLoading ? (
              <CircularProgress style={{ color: primaryColor }} />
            ) : (
              <Button
                variant="contained"
                className={classes.loginBtn}
                size="small"
                onClick={handleForgot}
                disableRipple
              >
                <Typography className={classes.loginBtnText}>
                  confirm
                </Typography>
              </Button>
            )}
          </div>
        </div>
      </AuthBackground>
    </>
  );
};

export default ForgotPassword;
