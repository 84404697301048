import { useState, useEffect, useRef } from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  Box,
  Stack,
} from "@mui/material";
import DynamicTable from "../../../components/dynamicTable/index";
import NavigationDrawer from "../../../components/navigationDrawer";
import categoryStyle from "./style";
import Loader from "../../../components/loader";
import SnackbarAlert from "../../../components/snackbar/SnackbarAlert";
import { useLocation } from "react-router-dom";
import EditStudentDisCount from "./editStudentDiscount";
import { getAllStudentsByBatchId } from "../../../services/members/batch/Batch";
import FeesDialog from "./feesDialog";
import { pdf, PDFViewer } from "@react-pdf/renderer";
import FileSaver from "file-saver";
import IeltsPdf from "./confirmStudentPdf";
import DeleteHelper from "../../../components/alert/deleteHelper";
import RefundDialog from "./refundDialog";
import {
  deleteStudent,
  updateStudentById,
} from "../../../services/members/studentRegistartion/StudentRegistartion";
import AllStudentPdf from "./allStudentsPdf";
import { CSVLink } from "react-csv";
import AttendancePdf from "./AttendanceSheetpdf";
import IpdCertificate from "./ipdCertificate";

const csvHeaders = [
  {
    label: "Name",
    key: "name",
  },
  {
    label: "Email",
    key: "email",
  },
  {
    label: "Age",
    key: "age",
  },
  {
    label: "Contact",
    key: "contact",
  },
  {
    label: "Course",
    key: "courseName",
  },
  {
    label: "Batch",
    key: "batchName",
  },
  {
    label: "CNIC",
    key: "cnicNo",
  },
  {
    label: "Jamat Khana",
    key: "jamatKhana",
  },
  {
    label: "Local Council",
    key: "localCouncil",
  },
  {
    label: "Qualification",
    key: "qualification",
  },
  {
    label: "School/University",
    key: "school",
  },
  {
    label: "Address",
    key: "address",
  },
  {
    label: "Computes SKill",
    key: "computerSkill",
  },
  {
    label: "Info Source",
    key: "infoSourse",
  },
];

const StudentsDetailPage = () => {
  const csvLinkRef = useRef(null);

  const userData = JSON.parse(localStorage.getItem("user"));
  const classes = categoryStyle();
  const routerLocation = useLocation();
  const stateData = routerLocation.state;
  const [type, setType] = useState("add");
  const [openDialog, setOpenDialog] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [dialogData, setDialgData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [filterArr, setFilterArr] = useState([]);
  const [searchVal, setSearchVal] = useState("All");
  const [openFeesDial, setOpenFeesDial] = useState(false);
  const [openRefundDial, setOpenRefundDial] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [refund, setRefund] = useState(0);
  const [feesPaid, setFeesPaid] = useState("");
  const [discountBy, setDiscountBy] = useState("");
  const [receivedBy, setReceivedBy] = useState("");
  const [refundBy, setRefundBy] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const [allConfirmedStudents, setAllConfirmedStudents] = useState([]);

  const headerData = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "ID",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: true,
      label: "Email",
    },
    {
      id: "contact",
      numeric: false,
      disablePadding: true,
      label: "Contact",
    },
    {
      id: "chat",
      numeric: false,
      disablePadding: true,
      label: "Chat",
    },
    {
      id: "feesPaid",
      numeric: false,
      disablePadding: true,
      label: "Fees Paid",
    },
    {
      id: "receivedBy",
      numeric: false,
      disablePadding: true,
      label: "Received By",
    },
    {
      id: "discount",
      numeric: false,
      disablePadding: true,
      label: "Discount",
    },
    {
      id: "discountBy",
      numeric: false,
      disablePadding: true,
      label: "Discount By",
    },
    {
      id: "refund",
      numeric: false,
      disablePadding: true,
      label: "Refund",
    },
    {
      id: "refundBy",
      numeric: false,
      disablePadding: true,
      label: "Refund By",
    },
  ].concat(
    userData?.role === "Member"
      ? [
          {
            id: "confirmed",
            numeric: false,
            disablePadding: true,
            label: "Confirmed",
          },
          {
            id: "dropped",
            numeric: false,
            disablePadding: true,
            label: "Dropped",
          },
        ]
      : userData?.role === "ob"
      ? []
      : [
          {
            id: "confirmed",
            numeric: false,
            disablePadding: true,
            label: "Confirmed",
          },
          {
            id: "dropped",
            numeric: false,
            disablePadding: true,
            label: "Dropped",
          },
          {
            id: "actions",
            numeric: false,
            disablePadding: true,
            label: "Action",
          },
          {
            id: "feesSubmit",
            numeric: false,
            disablePadding: true,
            label: "Fees Submit",
          },
          {
            id: "refung",
            numeric: false,
            disablePadding: true,
            label: "Fees Refund",
          },
        ]
  );

  const diplayRows = [
    "id",
    "name",
    "email",
    "contact",
    "whats_app_chat",
    "feesPaid",
    "receivedBy",
    "discount",
    "discountBy",
    "refund",
    "refundBy",
  ].concat(
    userData?.role === "Member"
      ? ["isConfirmed", "isDropped"]
      : userData?.role === "ob"
      ? []
      : ["isConfirmed", "isDropped", "actions", "feesSubmit", "refundFees"]
  );

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  useEffect(() => {
    handleGetAllStudentsByBatch();
  }, []);

  const handleGetAllStudentsByBatch = () => {
    setIsLoading(true);
    getAllStudentsByBatchId(stateData?._id)
      .then((response) => {
        setIsLoading(false);
        if (response?.status == 200) {
          setRowsData(response?.data?.data);
          setFilterArr(response?.data?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", error?.message);
      });
  };

  const handleDeleteStudent = (val) => {
    DeleteHelper(true, val._id, deleteStudent, handleGetAllStudentsByBatch);
  };

  const openEditDialog = (row, ind) => {
    setOpenDialog(true);
    setDialgData(row);
    setActiveIndex(ind);
    setType("edit");
  };

  const openSubmitDialog = (row, ind) => {
    setOpenFeesDial(true);
    setDialgData(row);
    setActiveIndex(ind);
    setType("edit");
  };

  const openRefundDialog = (row, ind) => {
    setOpenRefundDial(true);
    setDialgData(row);
    setType("edit");
  };

  const handleClose = () => {
    setDiscount("");
    setFeesPaid("");
    setDiscountBy("");
    setReceivedBy("");
    setOpenDialog(false);
    setOpenFeesDial(false);
    setOpenRefundDial(false);
    setActiveIndex(null);
    setDialgData(null);
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const handleIsConfirmed = (e, roww, ind) => {
    let paylaod = {
      isConfirmed: !roww.isConfirmed,
    };
    setIsLoading(true);
    updateStudentById(roww?._id, paylaod)
      .then((res) => {
        setIsLoading(false);
        if (res.status == 200) {
          handleGetAllStudentsByBatch();
          handleSnackbarVal(true, "success", res?.data?.message);
        } else {
          setIsLoading(false);
          handleSnackbarVal(true, "error", res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const handleIsDropped = (e, roww, ind) => {
    let paylaod = {
      isDropped: !roww.isDropped,
    };
    setIsLoading(true);
    updateStudentById(roww?._id, paylaod)
      .then((res) => {
        setIsLoading(false);
        if (res.status == 200) {
          handleGetAllStudentsByBatch();
          handleSnackbarVal(true, "success", res?.data?.message);
        } else {
          setIsLoading(false);
          handleSnackbarVal(true, "error", res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleChangeFilter = (e) => {
    let valueee = e.target.value;
    setSearchVal(valueee);
    if (valueee == "All") {
      setFilterArr(rowsData);
    } else if (valueee == "isConfirmed") {
      let filteredArr = rowsData.filter((item) => {
        return item.isConfirmed;
      });
      setFilterArr(filteredArr);
    } else {
      let filteredArr = rowsData.filter((item) => {
        return item.isDropped;
      });
      setFilterArr(filteredArr);
    }
  };

  const handleFilterByEmail = (event) => {
    const value = event.target.value;
    setSearchBy(value);

    let filterData = rowsData?.filter(
      (item) =>
        item?.email.toLowerCase().includes(value.toLowerCase()) ||
        item?.name.toLowerCase().includes(value.toLowerCase()) ||
        item?.contact.toLowerCase().includes(value.toLowerCase())
    );

    setFilterArr(filterData);
  };

  const handlePush = async () => {
    let allPdfData = [];
    rowsData.map((item) => {
      if (item.isConfirmed) {
        allPdfData.push({
          name: item?.name,
          contact: item?.contact,
          totalFees: stateData?.totalFees,
          feesPaid: item?.feesPaid,
          discount: item?.discount,
          courseName: stateData?.courseName,
          discountBy: item?.discountBy,
          refund: item?.refund,
        });
      }
    });

    const overAllFees = allPdfData.reduce(
      (acc, curr) => acc + curr.totalFees,
      0
    );
    const overAllPaid = allPdfData.reduce(
      (acc, curr) => acc + curr.feesPaid,
      0
    );

    const overAllDiscount = allPdfData.reduce(
      (acc, curr) => acc + curr.discount,
      0
    );

    const overAllRefund = allPdfData.reduce(
      (acc, curr) => acc + curr.refund,
      0
    );

    const overAllFeesWithRefund = overAllFees - overAllRefund;

    const teacherShareOnTotalFess =
      (overAllFeesWithRefund * stateData.teacherShare) / 100;

    const overAllDue = overAllFeesWithRefund - overAllPaid - overAllDiscount;

    const totalIncome =
      overAllFeesWithRefund -
      teacherShareOnTotalFess -
      overAllDiscount -
      overAllDue;

    let batchReport = {
      teacherShare: stateData.teacherShare,
      overAllFees,
      overAllPaid,
      overAllDue,
      overAllDiscount,
      overAllRefund,
      overAllFeesWithRefund,
      teacherShareOnTotalFess,
      totalIncome,
    };

    console.log(batchReport, "batchReportbatchReportbatchReportbatchReport");

    const blob = await pdf(
      <IeltsPdf
        allPdfData={allPdfData}
        batchReport={batchReport}
        teacherShare={stateData.teacherShare}
      />
    ).toBlob();
    FileSaver.saveAs(blob, `${stateData.batchName}`);
  };

  const handleExportAllStudents = async () => {
    let allPdfData = [];
    rowsData.map((item) => {
      allPdfData.push({
        name: item?.name,
        contact: item?.contact,
        totalFees: stateData?.totalFees,
        feesPaid: item?.feesPaid,
        discount: item?.discount,
        courseName: stateData?.courseName,
        discountBy: item?.discountBy,
        refund: item?.refund,
      });
    });

    const blob = await pdf(
      <AllStudentPdf
        allPdfData={allPdfData}
        teacherShare={stateData.teacherShare}
      />
    ).toBlob();
    FileSaver.saveAs(blob, `${stateData.batchName}`);
  };

  const handleExportAttendanceSheet = async () => {
    let allPdfData = [];
    rowsData.map((item) => {
      if (item.isConfirmed) {
        allPdfData.push({
          name: item?.name,
          contact: item?.contact,
          totalFees: stateData?.totalFees,
          feesPaid: item?.feesPaid,
          discount: item?.discount,
          courseName: stateData?.courseName,
          discountBy: item?.discountBy,
          refund: item?.refund,
        });
      }
    });

    const blob = await pdf(
      <AttendancePdf
        allPdfData={allPdfData}
        teacherShare={stateData.teacherShare}
      />
    ).toBlob();
    FileSaver.saveAs(blob, `${stateData.batchName}-Attendance-sheet`);
  };

  const handleExportConfirmCsv = () => {
    let tempArray = [];
    rowsData.map((item) => {
      if (item.isConfirmed) {
        tempArray.push(item);
      }
    });
    setAllConfirmedStudents(tempArray);
  };

  useEffect(() => {
    if (allConfirmedStudents?.length > 0) {
      csvLinkRef.current.link.click();
    }
  }, [allConfirmedStudents]);

  return (
    <NavigationDrawer>
      <Loader isloading={isLoading} />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />

      <Grid container alignItems="center">
        <Grid item xs={12} md={2}>
          <Typography style={{ fontSize: "22px", fontWeight: "600" }}>
            Students Details
          </Typography>
        </Grid>
      </Grid>

      <Grid container alignItems="center" mt={2}>
        <Grid item xs={12} md={5}>
          <Typography style={{ fontSize: "22px", fontWeight: "600" }}>
            {`${stateData.courseName} / ${stateData.batchName}`}
          </Typography>
        </Grid>
      </Grid>

      <Grid container m={"20px 0"}>
        <Grid item xs={12} md={6}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={"10px"}
            flexWrap={"wrap"}
          >
            <Button
              variant="contained"
              onClick={handlePush}
              sx={{
                backgroundColor: "rgb(248, 210, 71)",
                "&:hover": {
                  backgroundColor: "#000",
                },
              }}
            >
              Confirm Students
            </Button>
            <Button
              variant="contained"
              onClick={handleExportAllStudents}
              sx={{
                backgroundColor: "rgb(248, 210, 71)",
                "&:hover": {
                  backgroundColor: "#000",
                },
              }}
            >
              All Students
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            flexWrap={"wrap"}
            gap={"10px"}
          >
            <FormControl
              className={classes.durationSelect}
              size="small"
              sx={{ width: { xs: "100%", md: "48%" } }}
            >
              <Select
                displayEmpty
                value={searchVal}
                onChange={handleChangeFilter}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="isConfirmed">Confirmed</MenuItem>
                <MenuItem value="isDropped">Dropped</MenuItem>
              </Select>
            </FormControl>

            <TextField
              placeholder="Search here"
              size="small"
              sx={{ width: { xs: "100%", md: "48%" } }}
              value={searchBy}
              onChange={handleFilterByEmail}
            />
          </Stack>
        </Grid>
      </Grid>

      <CSVLink
        data={allConfirmedStudents}
        headers={csvHeaders}
        filename={`${stateData.batchName}-confirmed.csv`}
        style={{ display: "none" }}
        ref={csvLinkRef}
      ></CSVLink>

      <Button
        variant="contained"
        onClick={handleExportAttendanceSheet}
        sx={{
          backgroundColor: "rgb(248, 210, 71)",
          marginBottom: 1,
          "&:hover": {
            backgroundColor: "#000",
          },
        }}
      >
        Attendance Sheet
      </Button>

      <br />

      <Button
        variant="contained"
        onClick={handleExportConfirmCsv}
        sx={{
          backgroundColor: "rgb(248, 210, 71)",
          marginBottom: 1,
          "&:hover": {
            backgroundColor: "#000",
          },
        }}
      >
        Confirm Students Excel
      </Button>

      {/* <Box width={"100%"} height={"100%"} sx={{ overflow: "auto" }}>
        <PDFViewer
          showToolbar={true}
          style={{ width: "100%", height: "1000px" }}
        >
          <IpdCertificate />
        </PDFViewer>
      </Box> */}

      {/* >> Table */}
      <DynamicTable
        headerData={headerData}
        bodyRows={filterArr}
        displayData={diplayRows}
        showDelete={true}
        showEdit={true}
        handleIsConfirmed={handleIsConfirmed}
        handleIsDropped={handleIsDropped}
        openEditDialog={openEditDialog}
        type={type}
        openSubmitDialog={openSubmitDialog}
        handleDelete={handleDeleteStudent}
        openRefundDialog={openRefundDialog}
      />

      <EditStudentDisCount
        open={openDialog}
        setOpenDialog={setOpenDialog}
        type={type}
        discount={discount}
        setDiscount={setDiscount}
        discountBy={discountBy}
        setDiscountBy={setDiscountBy}
        handleClose={handleClose}
        dialogData={dialogData}
        setIsLoading={setIsLoading}
        handleGetAllStudentsByBatch={handleGetAllStudentsByBatch}
        isLoading={isLoading}
        setFilterArr={setFilterArr}
        filterArr={filterArr}
        activeIndex={activeIndex}
        handleSnackbarVal={handleSnackbarVal}
        receivedBy={receivedBy}
        setReceivedBy={setReceivedBy}
      />

      <FeesDialog
        open={openFeesDial}
        setOpenDialog={setOpenFeesDial}
        type={type}
        feesPaid={feesPaid}
        setFeesPaid={setFeesPaid}
        handleClose={handleClose}
        dialogData={dialogData}
        setIsLoading={setIsLoading}
        handleGetAllStudentsByBatch={handleGetAllStudentsByBatch}
        isLoading={isLoading}
        setFilterArr={setFilterArr}
        filterArr={filterArr}
        activeIndex={activeIndex}
        handleSnackbarVal={handleSnackbarVal}
        receivedBy={receivedBy}
        setReceivedBy={setReceivedBy}
        totalCourseFees={stateData.totalFees}
        courseName={stateData.courseName}
      />

      <RefundDialog
        type={type}
        open={openRefundDial}
        dialogData={dialogData}
        refund={refund}
        isLoading={isLoading}
        refundBy={refundBy}
        totalCourseFees={stateData.totalFees}
        courseName={stateData.courseName}
        handleClose={handleClose}
        setOpenDialog={setOpenRefundDial}
        handleSnackbarVal={handleSnackbarVal}
        handleGetAllStudentsByBatch={handleGetAllStudentsByBatch}
        setFeesPaid={setFeesPaid}
        setIsLoading={setIsLoading}
        setRefundBy={setRefundBy}
        setRefund={setRefund}
      />
    </NavigationDrawer>
  );
};

export default StudentsDetailPage;
