import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Button,
  Link,
  Avatar,
  IconButton,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import dynamicTableStyle from "./style";
import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import { allColors, primaryColor } from "../../constants/colors";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Done, Close } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { headerData, order, orderBy, onRequestSort, classes } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className={classes.headerRowsStyle}>
        {headerData?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label !== "Actions" ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function TableForStudents({
  headerData,
  bodyRows,
  displayData,
  showView,
  showEdit,
  showDelete,
  openEditDialog,
  handleDelete,
  navigateToDetail,
  handleEditStatus,
  userType,
  setCurrentPage,
  handleNavigate,
  paginationtozero,
  setPaginationToZero,
}) {
  const classes = dynamicTableStyle();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(
    JSON.parse(localStorage.getItem("currentPage")) || 0
  );
  console.log(page, "Fsdffsdffsdfgsd");

  const handleChangePage = (val) => {
    localStorage.setItem("currentOrderPage", val);
    setPage(val);
    setCurrentPage(val);
  };

  useEffect(() => {
    if (paginationtozero) {
      localStorage.setItem("currentProductPage", 0);
      setPage(0);
      setCurrentPage(0);
      setPaginationToZero(false);
    }
  }, [paginationtozero]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  console.log(bodyRows.length, "lennnnnnnnnnnnnnnnnnnnnn");

  return (
    <Box sx={{ width: "100%", boxShadow: "0 0.2rem 1rem rgb(0 0 0 / 12%)" }}>
      <TableContainer>
        <Table sx={{ minWidth: 1000 }} size="small" stickyHeader>
          <EnhancedTableHead
            numSelected={selected?.length}
            order={order}
            orderBy={orderBy}
            // onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={bodyRows?.length}
            headerData={headerData}
            classes={classes}
          />
          <TableBody>
            {stableSort(bodyRows, getComparator(order, orderBy))?.map(
              (row, index) => {
                console.log(row, "ffffsdfsdfsdfsd");
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    padding="normal"
                    className={classes.tableRowsStyle}
                    sx={{
                      cursor: "pointer",
                      "& .MuiTableCell-root ": {
                        backgroundColor: "#fff",
                      },
                    }}
                  >
                    {displayData?.map((val, i) => {
                      return (
                        <React.Fragment key={val}>
                          {val == "id" ? (
                            <TableCell padding="normal">{index + 1}</TableCell>
                          ) : val == "coverImage" ? (
                            <TableCell>
                              <Box height={60} width={60}>
                                <img
                                  src={row.coverImage}
                                  height={"100%"}
                                  width={"100%"}
                                />
                              </Box>
                            </TableCell>
                          ) : val !== "actions" ? (
                            <TableCell padding="normal">{row[val]}</TableCell>
                          ) : (
                            <TableCell padding="normal">
                              {showView && (
                                <RemoveRedEye
                                  style={{
                                    color: primaryColor,
                                    fontSize: "20px",
                                    cursor: "pointer",
                                    marginRight: "10px",
                                  }}
                                  onClick={() => navigateToDetail(row)}
                                />
                              )}
                              {showEdit && (
                                <Edit
                                  style={{
                                    marginRight: "10px",
                                    color: primaryColor,
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => openEditDialog(row, index)}
                                />
                              )}
                              {showDelete && (
                                <Delete
                                  style={{
                                    color: "red",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleDelete(row, index)}
                                />
                              )}
                            </TableCell>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "11%",
          }}
        >
          <IconButton
            disabled={page == 0 ? true : false}
            onClick={() => handleChangePage(page - 1)}
          >
            <ChevronLeftIcon style={{ fontSize: "25px" }} />
          </IconButton>
          <p style={{ margin: "0 5px" }}>{page + 1}</p>
          <IconButton
            disabled={bodyRows.length < 20 ? true : false}
            onClick={() => handleChangePage(page + 1)}
          >
            <ChevronRightIcon style={{ fontSize: "25px" }} />
          </IconButton>
        </div>
      </div>
    </Box>
  );
}
