import Api from "../../index";
import { requestType, endPoints } from "../../../constants/variables";

export const registerStudent = (params) => {
  return Api(endPoints.registerStudent, params, requestType.POST);
};
export const updateStudentById = (id, params) => {
  return Api(`${endPoints.updateStudentById}/${id}`, params, requestType.PUT);
};
export const deleteStudent = (id) => {
  return Api(`${endPoints.deleteStudent}/${id}`, null, requestType.DELETE);
};

export const getAllStudentsByCourseId = (id) => {
  return Api(
    `${endPoints.getAllStudentsByCourseId}/${id}`,
    null,
    requestType.GET
  );
};
export const getAllStudents = (id) => {
  return Api(`${endPoints.getAllStudents}/${id}`, null, requestType.GET);
};

export const getAllStudentsByRegistrarId = (createdBy, params, search) => {
  console.log(search, "ASAsasaSAsaSA");
  return Api(
    `${endPoints.getAllStudentsByRegistrarId}/${createdBy}?page=${params}`,
    search,
    requestType.POST
  );
};
