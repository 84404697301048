import React, { useState, useEffect } from "react";
import {
  DialogContent,
  TextField,
  InputLabel,
  Grid,
  Tooltip,
  Button,
} from "@mui/material";
import useStyles from "../../../../globalStyles";
import BootstrapDialog from "../../../../components/dialog";
import DialogHeader from "../../../../components/dialog/dialogHeader";
import DialogFooter from "../../../../components/dialog/dialogFooter";
import { registerMember } from "../../../../services/members/memberAuthentication/MemberAuth";
import { updateMembersProfile } from "../../../../services/members/memberProfile/MemberProfile";
import ImageUploader from "../../../../components/imageUploader";
import DeleteIcon from "@mui/icons-material/Delete";

export default function MemberAddEditDialog({
  open,
  type,
  inputValue,
  setInputValue,
  handleClose,
  getAllMembers,
  dialogData,
  setIsLoading,
  handleSnackbarVal,
}) {
  const userData = JSON.parse(localStorage.getItem("user"));
  const classes = useStyles();
  const [image, setImage] = useState("");
  const validNumber = /^03\d{9}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    if (dialogData) {
      setInputValue({
        name: dialogData?.name,
        email: dialogData?.email,
        contact: dialogData?.contact,
        age: dialogData?.age,
        dob: dialogData?.dob,
        cnic: dialogData?.cnicNo,
        profession: dialogData?.profession,
        address: dialogData?.address,
        gender: dialogData?.gender,
        education: dialogData?.education,
        otherInsitutions: dialogData?.otherInsitutions,
        fatherName: dialogData?.fatherName,
      });
      setImage(dialogData?.image);
    }
    if (type == "add") {
      handleAddInstitute();
    }
  }, [dialogData]);

  const handleChangeInput = (e) => {
    setInputValue({
      ...inputValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleBtn = async () => {
    console.log(inputValue, "dialogDatadialogDatadialogData");
    if (
      inputValue.name == "" ||
      inputValue.email == "" ||
      inputValue.password == "" ||
      inputValue.contact == "" ||
      inputValue.address == "" ||
      inputValue.dob == "" ||
      inputValue.fatherName == "" ||
      inputValue.profession == "" ||
      inputValue.address == "" ||
      inputValue.education == "" ||
      inputValue.gender == "" ||
      inputValue.cnic == "" ||
      inputValue.otherInsitutions == []
    ) {
      handleSnackbarVal(true, "error", `All Field are required`);
    } else if (!validNumber.test(inputValue.contact)) {
      handleSnackbarVal(true, "error", `Invalid contact number`);
    } else if (!emailRegex.test(inputValue.email)) {
      handleSnackbarVal(true, "error", `Invalid Email Format`);
    } else if (inputValue.cnic.slice(0, 1) === "-") {
      handleSnackbarVal(true, "error", `Enter a valid cnic number`);
    } else {
      if (type === "add") {
        let payload = {
          name: inputValue.name,
          password: inputValue.password,
          email: inputValue.email,
          contact: inputValue.contact,
          fatherName: inputValue?.fatherName,
          gender: inputValue?.gender,
          profession: inputValue?.profession,
          address: inputValue?.address,
          age: inputValue?.age,
          image: image,
          dob: inputValue?.dob,
          education: inputValue?.education,
          cnicNo: inputValue?.cnic,
          otherInsitutions: inputValue?.otherInsitutions,
        };
        setIsLoading(true);
        registerMember(payload)
          .then((res) => {
            if (res?.status == 200) {
              setInputValue({
                name: "",
                password: "",
                email: "",
                contact: "",
                fatherName: "",
                profession: "",
                address: "",
                age: "",
                dob: "",
                education: "",
                otherInsitutions: [],
                gender: "",
              });
              setImage("");
              setIsLoading(false);
              handleSnackbarVal(true, "success", res.data.message);
              getAllMembers();
              handleClose();
            } else {
              setIsLoading(false);
              handleSnackbarVal(true, "error", res?.data?.message);
            }
          })
          .catch((error) => {
            handleClose();
            console.log(error, "asdasdsada");
            setIsLoading(false);
            handleSnackbarVal(true, "error", error?.response?.data?.message);
          });
      } else {
        let payload = {
          name: inputValue.name,
          contact: inputValue.contact,
          fatherName: inputValue?.fatherName,
          gender: inputValue?.gender,
          profession: inputValue?.profession,
          address: inputValue?.address,
          age: inputValue?.age,
          image: image,
          dob: inputValue?.dob,
          education: inputValue?.education,
          otherInsitutions: inputValue?.otherInsitutions,
          adminId: userData?._id,
          role: "Member",
        };
        setIsLoading(true);
        updateMembersProfile(dialogData._id, payload)
          .then((response) => {
            if (response?.status == 200) {
              setInputValue({
                name: "",
                password: "",
                email: "",
                contact: "",
                fatherName: "",
                profession: "",
                address: "",
                age: "",
                dob: "",
                education: "",
                otherInsitutions: [],
                gender: "",
              });
              setImage("");
              setIsLoading(false);
              handleClose();
              getAllMembers();
            } else {
              setIsLoading(false);
              handleSnackbarVal(true, "error", response?.data?.message);
            }
          })
          .catch((error) => {
            handleSnackbarVal(true, "error", error?.response?.data?.message);
            setIsLoading(false);
            handleClose();
          });
      }
    }
  };

  const handleAddInstitute = () => {
    let tempArr = [...inputValue?.otherInsitutions];
    tempArr.push({ name: "" });
    setInputValue({
      ...inputValue,
      otherInsitutions: tempArr,
    });
  };

  const handleRemoveInstitute = (ind) => {
    let tempArr = [...inputValue?.otherInsitutions];
    tempArr.splice(ind, 1);
    setInputValue({
      ...inputValue,
      otherInsitutions: tempArr,
    });
  };

  let handleChangeName = (value, ind) => {
    let tempArr = [...inputValue?.otherInsitutions];
    tempArr[ind].name = value;
    setInputValue({
      ...inputValue,
      otherInsitutions: tempArr,
    });
  };

  return (
    <div>
      <BootstrapDialog onClose={handleClose} open={open}>
        <DialogHeader
          title={type == "add" ? "Add Member" : "Edit Member"}
          handleClose={handleClose}
        />
        <DialogContent dividers>
          <ImageUploader type="product" image={image} setImage={setImage} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Member Name
              </InputLabel>
              <TextField
                autoFocus
                placeholder="Member Name"
                variant="outlined"
                size="small"
                name="name"
                className={classes.globalInput}
                value={inputValue.name}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Father Name
              </InputLabel>
              <TextField
                autoFocus
                placeholder="Father Name"
                variant="outlined"
                size="small"
                name="fatherName"
                className={classes.globalInput}
                value={inputValue.fatherName}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Email
              </InputLabel>
              <TextField
                placeholder="Email"
                variant="outlined"
                size="small"
                name="email"
                className={classes.globalInput}
                value={inputValue.email}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Profession
              </InputLabel>
              <TextField
                autoFocus
                placeholder="Profession"
                variant="outlined"
                size="small"
                name="profession"
                className={classes.globalInput}
                value={inputValue.profession}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Address
              </InputLabel>
              <TextField
                autoFocus
                placeholder="Address"
                variant="outlined"
                size="small"
                name="address"
                className={classes.globalInput}
                value={inputValue.address}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Cnic No
              </InputLabel>
              <TextField
                type="number"
                InputProps={{
                  inputProps: { min: 0 },
                }}
                autoFocus
                placeholder=" Cnic No"
                variant="outlined"
                size="small"
                name="cnic"
                className={classes.globalInput}
                value={inputValue.cnic}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>Age</InputLabel>
              <TextField
                autoFocus
                placeholder="Age"
                variant="outlined"
                size="small"
                name="age"
                type={"number"}
                className={classes.globalInput}
                value={inputValue.age}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Date of Birth
              </InputLabel>
              <TextField
                type="date"
                autoFocus
                placeholder="Date of Birth"
                variant="outlined"
                size="small"
                name="dob"
                className={classes.globalInput}
                value={inputValue.dob}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Gender
              </InputLabel>
              <select
                value={inputValue.gender}
                onChange={handleChangeInput}
                name="gender"
                className="select-option-style"
                style={{ width: "100%", padding: "11px" }}
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>;
                <option value="Female">Female</option>;
                <option value="None">None of them</option>;
              </select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Eduaction
              </InputLabel>
              <TextField
                autoFocus
                placeholder="Education"
                variant="outlined"
                size="small"
                name="education"
                className={classes.globalInput}
                value={inputValue.education}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Contact
              </InputLabel>
              <TextField
                type="number"
                placeholder="Contact"
                variant="outlined"
                size="small"
                name="contact"
                className={classes.globalInput}
                value={inputValue.contact}
                onChange={handleChangeInput}
              />
            </Grid>
            {type !== "edit" && (
              <Grid item xs={12} sm={6}>
                <InputLabel className={classes.globalInputLabel}>
                  Password
                </InputLabel>
                <TextField
                  placeholder="Password"
                  variant="outlined"
                  size="small"
                  name="password"
                  className={classes.globalInput}
                  value={inputValue.password}
                  onChange={handleChangeInput}
                />
              </Grid>
            )}
            {inputValue?.otherInsitutions?.map((item, ind) => {
              return (
                <Grid
                  container
                  alignItems="flex-end"
                  marginLeft={"15px"}
                  marginTop={"12px"}
                >
                  <Grid item xs={12} sm={6}>
                    <InputLabel className={classes.globalInputLabel}>
                      Other Insitutions
                    </InputLabel>
                    <TextField
                      placeholder="Institue Name"
                      variant="outlined"
                      size="small"
                      className={classes.globalInput}
                      value={item?.name}
                      onChange={(e) => handleChangeName(e.target.value, ind)}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title="Remove Institute" arrow>
                      <DeleteIcon
                        style={{
                          color: "red",
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleRemoveInstitute(ind)}
                      />
                    </Tooltip>
                  </Grid>
                  <Button
                    variant="contained"
                    size="small"
                    className={classes.globalBtnStyle}
                    onClick={handleAddInstitute}
                  >
                    Add Institue
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogFooter
          type={type}
          handleCreate={handleBtn}
          handleUpdate={handleBtn}
          handleClose={handleClose}
        />
      </BootstrapDialog>
    </div>
  );
}
