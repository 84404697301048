import { useState, useEffect } from 'react';
import { Grid, TextField, Typography, Button, Container } from '@mui/material';
import DynamicTable from '../../../../components/dynamicTable';
import NavigationDrawer from '../../../../components/navigationDrawer';
import categoryStyle from './style';
import MemberRegistrationsDialog from './memberRegistrationsDialog';
import DeleteHelper from './../../../../components/alert/deleteHelper';
import Loader from '../../../../components/loader';
import SnackbarAlert from '../../../../components/snackbar/SnackbarAlert';
// import { deleteRequest, getAllRequestsByAdmin } from '../../../../services/superAdmin/request/Request';

const headerData = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'courseName',
    numeric: false,
    disablePadding: true,
    label: 'Course',
  },
  {
    id: 'batchName',
    numeric: false,
    disablePadding: true,
    label: 'Batch',
  },
  {
    id: 'stautus',
    numeric: false,
    disablePadding: true,
    label: 'Status',
  },
  {
    id: 'discount',
    numeric: false,
    disablePadding: true,
    label: 'Discount',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Actions',
  },
];

const diplayRows = ['id', 'name', 'courseName', 'batchName', 'status','discount','actions'];

const MembersRegistrations = () => {
  const classes = categoryStyle();
  const user = JSON.parse(localStorage.getItem('user'));
  const [type, setType] = useState('add');
  const [openDialog, setOpenDialog] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [dialogData, setDialgData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: '',
    message: '',
  });

  const [inputValue, setInputValue] = useState({
    name: '',
    courseName: '',
    courseId: '',
    batchName: '',
    batchId: '',
    discount: '',
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const getAllRegistrationByMember = () => {
    // setIsLoading(true);
    // getAllRequestsByAdmin(user._id)
    //   .then((res) => {
    //     console.log(res, 'ressssss');
    //     let data = res.data.data ? res.data.data : {};
    //     console.log(data,"data============>>>>>");
    //     setIsLoading(false);
    //     setRowsData(data);
    //   })
    //   .catch((err) => {
    //     console.log(err, 'errr');
    //     setIsLoading(false);
    //   });
  };

  useEffect(() => {
    getAllRegistrationByMember();
  }, []);

  const openAddDialog = () => {
    setOpenDialog(true);
    setType('add');
  };

  const openEditDialog = (row) => {
    setOpenDialog(true);
    setDialgData(row);
    setType('edit');
  };

  const handleClose = () => {
    setOpenDialog(false);
    setInputValue({
      name: '',
    });
  };

  // const handleDelete = (val) => {
  //   DeleteHelper(true, val._id, deleteRequest, getAllRegistrationByMember);
  // };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, '', '');
  };

  return (
    <NavigationDrawer>
      <Loader isloading={isLoading} />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />

      <Grid container alignItems='center'>
        <Grid item xs={12} md={2}>
          <Typography style={{ fontSize: '22px', fontWeight: '600' }}>
            Send Request
          </Typography>
        </Grid>
      </Grid>
      <div style={{ textAlign: 'right', margin: '10px 0' }}>
        {/* <TextField placeholder='Search' size='small' /> */}
        <Button
          variant='contained'
          size='small'
          disableRipple
          className={classes.loginBtn}
          onClick={openAddDialog}
        >
          <Typography className={classes.loginBtnText}>Add New</Typography>
        </Button>
      </div>

      {/* >> Table */}
      <DynamicTable
        headerData={headerData}
        bodyRows={rowsData}
        displayData={diplayRows}
        showDelete={true}
        showEdit={true}
        openEditDialog={openEditDialog}
        type={type}
        // handleDelete={handleDelete}
      />

      <MemberRegistrationsDialog
        open={openDialog}
        setOpenDialog={setOpenDialog}
        type={type}
        inputValue={inputValue}
        setInputValue={setInputValue}
        handleClose={handleClose}
        getCategories={getAllRegistrationByMember}
        dialogData={dialogData}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        handleSnackbarVal={handleSnackbarVal}
      />
    </NavigationDrawer>
  );
};

export default MembersRegistrations;
