import React, { useEffect, useState } from "react";
import NavigationDrawer from "./../../../../components/navigationDrawer/index";
import { Box, Typography } from "@mui/material";
import { getOnlineStudent } from "../../../../services/admin/students/student";
import Loader from "../../../../components/loader";
import DynamicTable from "../../../../components/dynamicTable";
import EditStudentDialog from "./editStudentDialog";
import SnackbarAlert from "../../../../components/snackbar/SnackbarAlert";

function OnlineStuednt() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const [allStudents, setAllStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [inputValue, setInputValue] = useState({
    name: "",
    email: "",
    contact: "",
    courseId: "",
    batchId: "",
    age: "",
  });

  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  useEffect(() => {
    getAllStudentsData();
  }, []);

  const getAllStudentsData = () => {
    setIsLoading(true);
    getOnlineStudent()
      .then((res) => {
        let data = res.data.data.reverse();
        setIsLoading(false);
        setAllStudents(data);
      })
      .catch((err) => {
        console.log(err, "errr");
        setIsLoading(false);
      });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogData(null);
    setInputValue({
      name: "",
      email: "",
      contact: "",
      courseId: "",
      batchId: "",
      age: "",
    });
  };

  const openEditDialog = (row) => {
    setDialogData(row);
    setOpenDialog(true);
  };

  const headerData = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "ID",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: true,
      label: "Email",
    },
    {
      id: "age",
      numeric: false,
      disablePadding: true,
      label: "Age",
    },
    {
      id: "contact",
      numeric: false,
      disablePadding: true,
      label: "Contact",
    },
    {
      id: "cnic",
      numeric: false,
      disablePadding: true,
      label: "Cnic",
    },
    {
      id: "jamatKhana",
      numeric: false,
      disablePadding: true,
      label: "Jamat Khana",
    },
    {
      id: "localCouncil",
      numeric: false,
      disablePadding: true,
      label: "Local Council",
    },
    {
      id: "qualification",
      numeric: false,
      disablePadding: true,
      label: "Qualification",
    },
    {
      id: "school",
      numeric: false,
      disablePadding: true,
      label: "School/University",
    },
    {
      id: "address",
      numeric: false,
      disablePadding: true,
      label: "Address",
    },
    {
      id: "computerSkill",
      numeric: false,
      disablePadding: true,
      label: "Computer Skill",
    },
    {
      id: "infoSource",
      numeric: false,
      disablePadding: true,
      label: "Info Source",
    },
    {
      id: "courseName",
      numeric: false,
      disablePadding: true,
      label: "Course",
    },
    // Other header data...
    ...(userData?.role === "Admin"
      ? [
          {
            id: "actions",
            numeric: false,
            disablePadding: true,
            label: "Action",
          },
        ]
      : []),
  ];

  const diplayRows = [
    "id",
    "name",
    "email",
    "age",
    "contact",
    "cnicNo",
    "jamatKhana",
    "localCouncil",
    "qualification",
    "school",
    "address",
    "computerSkill",
    "infoSourse",
    "courseName",
    // "actions",
  ].concat(userData?.role === "Admin" ? "actions" : []);

  return (
    <NavigationDrawer>
      <Loader isloading={isLoading} />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Typography
        style={{
          fontSize: "22px",
          fontWeight: "600",
          marginBottom: "10px",
        }}
      >
        Online Registered Students
      </Typography>
      <Box mt={3}>
        <DynamicTable
          headerData={headerData}
          bodyRows={allStudents}
          displayData={diplayRows}
          showEdit={true}
          openEditDialog={openEditDialog}
        />
      </Box>
      <EditStudentDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        getAllStudentsData={getAllStudentsData}
        dialogData={dialogData}
        inputValue={inputValue}
        setInputValue={setInputValue}
        handleSnackbarVal={handleSnackbarVal}
        setIsLoading={setIsLoading}
      />
    </NavigationDrawer>
  );
}

export default OnlineStuednt;
