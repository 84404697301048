import Api from "../../index";
import { requestType, endPoints } from "../../../constants/variables";

export const createCourse = (params) => {
  return Api(endPoints.createCourse, params, requestType.POST);
};
export const updateCourseById = (id, params) => {
  return Api(`${endPoints.updateCourseById}/${id}`, params, requestType.PUT);
};
export const deleteCourse = (id) => {
  return Api(`${endPoints.deleteCourse}/${id}`, null, requestType.DELETE);
};
export const getAllCourses = (id) => {
  return Api(`${endPoints.getAllCourses}`, null, requestType.GET);
};
export const getAllCoursesByMemberId = (id) => {
  return Api(
    `${endPoints.getAllCoursesByMemberId}/${id}`,
    null,
    requestType.GET
  );
};

export const getTotalCourses = (params) => {
  return Api(endPoints.getTotalCourses, null, requestType.GET);
};
