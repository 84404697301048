import {
  Box,
  Button,
  Container,
  FormControl,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { appImages } from "../../../assets";
import { useState } from "react";
import { getTotalCourses } from "../../../services/admin/courses/Courses";
import SnackbarAlert from "../../../components/snackbar/SnackbarAlert";
import { registerOnlineStudent } from "../../../services/admin/students/student";
import Loader from "../../../components/loader";

const refundPolicy = [
  {
    percent: "100%",
    text: "refund after first class or (Orientation class)",
  },
  {
    percent: "75%",
    text: "refund after second class",
  },
  {
    percent: "50%",
    text: "refund after third class",
  },
  {
    percent: "No",
    text: "refund after fourth class",
  },
];

const rules = [
  "Eating, drinking and chewing gum is strictly not allowed.",
  "Any damages to IPD resources will be penalized.",
  "Course certificate will not be given if course is not completely paid.",
  "Certificates will only be provided to those students whose attendance will be more than 70% in the whole course.",
  "Any misbehave or using IPD resources wrongly will cause disqualification from the course.",
  "Using of cellphone is not allowed during class time.",
  "No fee will be carried forward to next course if you quit course after taking three classes.",
];

const source = [
  "Whatapp Message",
  "Whatsapp Status",
  "Announcement in JK",
  "Desk in JK",
  "JK's notice board",
  "Family and Friends",
  "Google Classroom",
  "Other:",
];

const validNumber = /^03\d{9}$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const validCnic = /^\d{13}$/;

function OnlineRegistrationForm() {
  const [skillPoint, setSkillPoint] = useState(0);
  const [refrence, setRefrence] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [allCourses, setAllCourses] = useState([]);

  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [registerVal, setRegisterVal] = useState({
    courseId: "",
    name: "",
    contact: "",
    email: "",
    age: "",
    cnicNo: "",
    jamatKhana: "",
    localCouncil: "",
    qualification: "",
    school: "",
    address: "",
    computerSkill: "",
    infoSourse: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  useEffect(() => {
    fetchTotalCourse();
  }, []);

  const fetchTotalCourse = () => {
    getTotalCourses()
      .then((response) => {
        if (response?.data?.status === "ok") {
          let data = response?.data?.data;
  
          // Sort the array by the 'name' property in alphabetical order
          let sorted = data.sort((a, b) => {
            if (a.name < b.name) return -1; // a comes before b
            if (a.name > b.name) return 1;  // a comes after b
            return 0;                       // a and b are equal
          });
  
          // Alternatively, you can use localeCompare for locale-aware sorting
          // let sorted = data.sort((a, b) => a.name.localeCompare(b.name));
  
          console.log(sorted, "Sorted Courses");
  
          setAllCourses(sorted);
        } else {
          handleSnackbarVal(true, "error", "Something went Wrong");
        }
      })
      .catch((errr) => {
        console.log(errr);
      });
  };
  

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setRegisterVal({
      ...registerVal,
      [name]: value,
    });
  };

  const handleSubmitForm = () => {
    const {
      courseId,
      name,
      age,
      contact,
      email,
      address,
      cnicNo,
      school,
      infoSourse,
      jamatKhana,
      localCouncil,
      qualification,
    } = registerVal;

    console.log(refrence, infoSourse, "ssssssssssss");
    if (!courseId) {
      handleSnackbarVal(true, "error", "Select a course");
    } else if (!name) {
      handleSnackbarVal(true, "error", "Name is required");
    } else if (!email) {
      handleSnackbarVal(true, "error", "Email is required");
    } else if (!emailRegex.test(email)) {
      handleSnackbarVal(true, "error", "Invalid Emial Format");
    } else if (!contact) {
      handleSnackbarVal(true, "error", "Contact is required");
    } else if (!validNumber.test(contact)) {
      handleSnackbarVal(true, "error", "Invalid contact number");
    } else if (!cnicNo) {
      handleSnackbarVal(true, "error", "CNIC or B Form number is required");
    } else if (!validCnic.test(cnicNo)) {
      handleSnackbarVal(true, "error", "Enter valid CNIC or B Form number");
    } else if (!age) {
      handleSnackbarVal(true, "error", "Age is required");
    } else if (!jamatKhana) {
      handleSnackbarVal(true, "error", "Jamat Khana name is required");
    } else if (!localCouncil) {
      handleSnackbarVal(true, "error", "Local council name is required");
    } else if (!qualification) {
      handleSnackbarVal(true, "error", "Qualification  is required");
    } else if (!school) {
      handleSnackbarVal(true, "error", "School or University name is required");
    } else if (!address) {
      handleSnackbarVal(true, "error", "Address is required");
    } else if (!skillPoint > 0) {
      handleSnackbarVal(true, "error", "Please Rate your computer skill ");
    } else if (!refrence) {
      handleSnackbarVal(true, "error", "Select any source");
    } else if (refrence == "Other:" && !infoSourse) {
      handleSnackbarVal(true, "error", "Write Your Source name");
    } else {
      let payload = {
        courseId,
        name,
        age,
        contact,
        email,
        address,
        cnicNo,
        school,
        infoSourse: refrence + infoSourse,
        computerSkill: skillPoint,
        jamatKhana,
        localCouncil,
        qualification,
      };
      console.log(payload, "payloaddddddddddddddddd");
      setIsLoading(true);
      registerOnlineStudent(payload)
        .then((response) => {
          setIsLoading(false);
          if (response?.data?.status === "ok") {
            handleSnackbarVal(true, "success", response?.data?.message);
            setRegisterVal({
              courseId: "",
              name: "",
              contact: "",
              email: "",
              age: "",
              cnicNo: "",
              jamatKhana: "",
              localCouncil: "",
              qualification: "",
              school: "",
              address: "",
              computerSkill: "",
              infoSourse: "",
              setSkillPoint: 0,
              setRefrence: "",
            });
            setSkillPoint(0);
            setRefrence("");
          } else {
            handleSnackbarVal(true, "error", response?.data?.message);
          }
        })
        .catch((errr) => {
          setIsLoading(false);
          handleSnackbarVal(
            true,
            "error",
            "Something went wrong while submitting your form"
          );
        });
    }
  };

  return (
    <Box bgcolor={"#f5f5f5"}>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Loader isloading={isLoading} />
      <Container sx={{ maxWidth: "700px !important" }}>
        <Box pt={2}>
          <img
            src={appImages.registartionPic}
            alt={"Registration form"}
            width={"100%"}
            style={{
              borderRadius: "10px",
            }}
          />
        </Box>
        <Box
          borderRadius={"10px"}
          border={"1px solid #dadce0"}
          borderTop={"10px solid #c5c4b8"}
          mt={2}
          p={"24px"}
          bgcolor={"#fff"}
        >
          <Typography
            variant={"h1"}
            fontWeight={500}
            sx={{
              fontSize: { xs: 18, sm: 22, md: 24 },
            }}
          >
            IPD Registration Form
          </Typography>
          <Typography
            variant={"body1"}
            fontSize={"14px"}
            fontWeight={400}
            mt={1}
          >
            Course registration form
          </Typography>
          <Typography
            variant={"body1"}
            fontSize={"15px"}
            fontWeight={700}
            mt={2}
            sx={{ textDecoration: "underline" }}
          >
            Fee Refund Policy:
          </Typography>
          <ul
            style={{
              listStyleType: "number",
            }}
          >
            {refundPolicy?.map(({ percent, text }, index) => {
              return (
                <li style={{ fontSize: "14px", padding: "3px 0px" }}>
                  <b>{percent}</b>
                  {" " + text}
                </li>
              );
            })}
          </ul>
          <Typography
            variant={"body1"}
            fontSize={"15px"}
            fontWeight={700}
            mt={2}
            sx={{ textDecoration: "underline" }}
          >
            Rules and Regulations:
          </Typography>
          <ul>
            {rules?.map((text, index) => {
              return (
                <li style={{ fontSize: "14px", padding: "3px 0px" }}>{text}</li>
              );
            })}
          </ul>
        </Box>
        <Box
          borderRadius={"10px"}
          border={"1px solid #dadce0"}
          mt={2}
          p={"24px"}
          bgcolor={"#fff"}
        >
          <Typography
            mb={1}
            variant={"body1"}
            fontWeight={500}
            color={"#5c5c5c"}
          >
            Name of course*
          </Typography>
          <FormControl fullWidth>
            <Select
              variant="standard"
              sx={{
                width: { xs: "100%", md: "60%" },
                "& .MuiInputBase-input": {
                  fontWeight: 500,
                },
              }}
              name="courseId"
              value={registerVal.courseId}
              onChange={handleChangeValue}
            >
              {allCourses?.map((courseeeeee) => {
                return (
                  <MenuItem value={courseeeeee?._id}>
                    {courseeeeee?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box
          borderRadius={"10px"}
          border={"1px solid #dadce0"}
          mt={2}
          p={"24px"}
          bgcolor={"#fff"}
        >
          <Typography
            mb={1}
            variant={"body1"}
            fontWeight={500}
            color={"#5c5c5c"}
          >
            Your Name*
          </Typography>
          <TextField
            type="text"
            variant="standard"
            placeholder="Your Answer"
            name="name"
            value={registerVal.name}
            onChange={handleChangeValue}
            sx={{
              width: { xs: "100%", md: "60%" },
              "& .MuiInputBase-input": {
                fontWeight: 500,
              },
            }}
          />
        </Box>

        <Box
          borderRadius={"10px"}
          border={"1px solid #dadce0"}
          mt={2}
          p={"24px"}
          bgcolor={"#fff"}
        >
          <Typography
            mb={1}
            variant={"body1"}
            fontWeight={500}
            color={"#5c5c5c"}
          >
            Email ID (Gmail ID)*
          </Typography>
          <TextField
            type="email"
            variant="standard"
            placeholder="Your Answer"
            name="email"
            value={registerVal.email}
            onChange={handleChangeValue}
            sx={{
              width: { xs: "100%", md: "60%" },
              "& .MuiInputBase-input": {
                fontWeight: 500,
              },
            }}
          />
        </Box>
        <Box
          borderRadius={"10px"}
          border={"1px solid #dadce0"}
          mt={2}
          p={"24px"}
          bgcolor={"#fff"}
        >
          <Typography
            mb={1}
            variant={"body1"}
            fontWeight={500}
            color={"#5c5c5c"}
          >
            Phone Number (Whatpp Number)*
          </Typography>
          <TextField
            type="number"
            variant="standard"
            placeholder="Your Answer"
            name="contact"
            value={registerVal.contact}
            onChange={handleChangeValue}
            sx={{
              width: { xs: "100%", md: "60%" },
              "& .MuiInputBase-input": {
                fontWeight: 500,
              },
            }}
          />
        </Box>
        <Box
          borderRadius={"10px"}
          border={"1px solid #dadce0"}
          mt={2}
          p={"24px"}
          bgcolor={"#fff"}
        >
          <Typography
            mb={1}
            variant={"body1"}
            fontWeight={500}
            color={"#5c5c5c"}
          >
            Cnic Number-(B Form){" "}
            <span style={{ fontSize: 14, fontWeight: 400 }}>
              ( Enter without dash)
            </span>
          </Typography>
          <TextField
            type="number"
            variant="standard"
            placeholder="Your Answer"
            name="cnicNo"
            value={registerVal.cnicNo}
            onChange={handleChangeValue}
            sx={{
              width: { xs: "100%", md: "60%" },
              "& .MuiInputBase-input": {
                fontWeight: 500,
              },
            }}
          />
        </Box>
        <Box
          borderRadius={"10px"}
          border={"1px solid #dadce0"}
          mt={2}
          p={"24px"}
          bgcolor={"#fff"}
        >
          <Typography
            mb={1}
            variant={"body1"}
            fontWeight={500}
            color={"#5c5c5c"}
          >
            Age*
          </Typography>
          <TextField
            type="number"
            variant="standard"
            name="age"
            value={registerVal.age}
            onChange={handleChangeValue}
            sx={{
              width: { xs: "100%", md: "60%" },
            }}
          />
        </Box>
        <Box
          borderRadius={"10px"}
          border={"1px solid #dadce0"}
          mt={2}
          p={"24px"}
          bgcolor={"#fff"}
        >
          <Typography
            mb={1}
            variant={"body1"}
            fontWeight={500}
            color={"#5c5c5c"}
          >
            Jamatkhana*
          </Typography>
          <TextField
            variant="standard"
            placeholder="Your Answer"
            name="jamatKhana"
            value={registerVal.jamatKhana}
            onChange={handleChangeValue}
            sx={{
              width: { xs: "100%", md: "60%" },
              "& .MuiInputBase-input": {
                fontWeight: 500,
              },
            }}
          />
        </Box>
        <Box
          borderRadius={"10px"}
          border={"1px solid #dadce0"}
          mt={2}
          p={"24px"}
          bgcolor={"#fff"}
        >
          <Typography
            mb={1}
            variant={"body1"}
            fontWeight={500}
            color={"#5c5c5c"}
          >
            Local Council*
          </Typography>
          <TextField
            variant="standard"
            placeholder="Your Answer"
            name="localCouncil"
            value={registerVal.localCouncil}
            onChange={handleChangeValue}
            sx={{
              width: { xs: "100%", md: "60%" },
              "& .MuiInputBase-input": {
                fontWeight: 500,
              },
            }}
          />
        </Box>
        <Box
          borderRadius={"10px"}
          border={"1px solid #dadce0"}
          mt={2}
          p={"24px"}
          bgcolor={"#fff"}
        >
          <Typography
            mb={1}
            variant={"body1"}
            fontWeight={500}
            color={"#5c5c5c"}
          >
            Qualification*
          </Typography>
          <TextField
            variant="standard"
            placeholder="Your Answer"
            name="qualification"
            value={registerVal.qualification}
            onChange={handleChangeValue}
            sx={{
              width: { xs: "100%", md: "60%" },
              "& .MuiInputBase-input": {
                fontWeight: 500,
              },
            }}
          />
        </Box>
        <Box
          borderRadius={"10px"}
          border={"1px solid #dadce0"}
          mt={2}
          p={"24px"}
          bgcolor={"#fff"}
        >
          <Typography
            mb={1}
            variant={"body1"}
            fontWeight={500}
            color={"#5c5c5c"}
          >
            School / University
          </Typography>
          <TextField
            variant="standard"
            placeholder="Your Answer"
            name="school"
            value={registerVal.school}
            onChange={handleChangeValue}
            sx={{
              width: { xs: "100%", md: "60%" },
              "& .MuiInputBase-input": {
                fontWeight: 500,
              },
            }}
          />
        </Box>
        <Box
          borderRadius={"10px"}
          border={"1px solid #dadce0"}
          mt={2}
          p={"24px"}
          bgcolor={"#fff"}
        >
          <Typography
            mb={1}
            variant={"body1"}
            fontWeight={500}
            color={"#5c5c5c"}
          >
            Address
          </Typography>
          <TextField
            variant="standard"
            placeholder="Your Answer"
            name="address"
            value={registerVal.address}
            onChange={handleChangeValue}
            sx={{
              width: { xs: "100%", md: "60%" },
              "& .MuiInputBase-input": {
                fontWeight: 500,
              },
            }}
          />
        </Box>
        <Box
          borderRadius={"10px"}
          border={"1px solid #dadce0"}
          mt={2}
          p={"24px"}
          bgcolor={"#fff"}
        >
          <Typography
            mb={1}
            variant={"body1"}
            fontWeight={500}
            color={"#5c5c5c"}
          >
            Please rate your computer skills.*
          </Typography>
          <Box
            display={"flex"}
            // sx={{ display: { xs: "block", sm: "flex" } }}
            justifyContent={"space-between"}
            alignItems={"end"}
            mt={4}
            flexWrap={"wrap"}
            gap={"20px"}
          >
            <Typography>Not Good</Typography>
            <Box display={"flex"} gap={"30px"}>
              {[1, 2, 3, 4, 5].map((item, index) => {
                return (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    gap={"15px"}
                    key={item}
                    // flexWrap={"wrap"}
                  >
                    <Typography variant={"body1"} fontSize={16}>
                      {item}
                    </Typography>
                    <Box
                      height={20}
                      width={20}
                      borderRadius={"50%"}
                      border={"2px solid #7a785f"}
                      sx={{ cursor: "pointer" }}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      onClick={() => {
                        setSkillPoint(item);
                      }}
                    >
                      <Box
                        height={12}
                        width={12}
                        borderRadius={"50%"}
                        bgcolor={item == skillPoint ? "#7a785f" : ""}
                      ></Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Typography>Very Good</Typography>
          </Box>
        </Box>
        <Box
          borderRadius={"10px"}
          border={"1px solid #dadce0"}
          mt={2}
          p={"24px"}
          bgcolor={"#fff"}
        >
          <Typography
            mb={1}
            variant={"body1"}
            fontWeight={500}
            color={"#5c5c5c"}
          >
            Source of Information about course
          </Typography>
          <Box mt={3}>
            {source.map((item, index) => {
              return (
                <Box
                  key={index}
                  display={"flex"}
                  alignItems={"center"}
                  gap={"10px"}
                  m={"10px 0"}
                >
                  <Box
                    height={18}
                    width={18}
                    borderRadius={"50%"}
                    border={"2px solid #7a785f"}
                    sx={{ cursor: "pointer" }}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    onClick={() => {
                      setRefrence(item);
                      console.log(item, "dasdsdsds");
                    }}
                  >
                    <Box
                      height={10}
                      width={10}
                      borderRadius={"50%"}
                      bgcolor={item == refrence ? "#7a785f" : ""}
                    ></Box>
                  </Box>
                  <Typography variant={"body1"} fontSize={15}>
                    {item}
                  </Typography>
                  {item == "Other:" && (
                    <TextField
                      variant="standard"
                      name="infoSourse"
                      value={registerVal.infoSourse}
                      onChange={handleChangeValue}
                      sx={{
                        width: { xs: "100%", md: "60%" },
                        "& .MuiInputBase-input": {
                          fontWeight: 500,
                        },
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box mt={2}>
          <Button
            variant={"contained"}
            onClick={handleSubmitForm}
            sx={{
              backgroundColor: "#7a785f",
              fontSize: 14,
              textTransform: "capitalize",
              fontWeight: 500,
              "&:hover": {
                backgroundColor: "#7a785f",
              },
            }}
          >
            Submit
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default OnlineRegistrationForm;
