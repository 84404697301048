import Api from "../../index";
import { requestType, endPoints } from "../../../constants/variables";

export const createBatch = (params) => {
  return Api(endPoints.createBatch, params, requestType.POST);
};
export const updateBatchById = (id, params) => {
  return Api(`${endPoints.updateBatchById}/${id}`, params, requestType.PUT);
};
export const deleteBatch = (id) => {
  return Api(`${endPoints.deleteBatch}/${id}`, null, requestType.DELETE);
};
export const getAllBatches = (id) => {
  return Api(`${endPoints.getAllBatches}`, null, requestType.GET);
};
export const getAllBatchesByCourseId = (id) => {
  return Api(
    `${endPoints.getAllBatchesByCourseId}/${id}`,
    null,
    requestType.GET
  );
};
export const getAllStudentsByBatchId = (id) => {
  return Api(
    `${endPoints.getAllStudentsByBatchId}/${id}`,
    null,
    requestType.GET
  );
};

export const getAllAsignedBatchByUserId = (id) => {
  return Api(`${endPoints.getAllBatchesByMember}/${id}`, null, requestType.GET);
};

export const updateBatchStatusById = (id, params) => {
  return Api(`${endPoints.updateClassStatus}/${id}`, params, requestType.PUT);
};
