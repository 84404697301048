import { makeStyles } from "@mui/styles";
import { primaryColor, secondaryColor } from "../../../../constants/colors";

const emailStyle = makeStyles((theme) => ({
  
  subjectField: {
    margin: "30px 0 0 30px !important",
    width: "70% !important",
  },
  imgBtn: {
    "&.MuiButton-root": {
      backgroundColor: primaryColor,
      color: "white !important",
      width: "20%",
      [theme.breakpoints.down(850)]: {
        width: "65%",
      },
      padding: "10px",
      marginLeft: "20px",
      boxShadow: "none !important",
      margin: "30px 0 0 30px !important",
      "&:hover": {
        backgroundColor: secondaryColor,
      },
    },
  },
  imgBtnText: {
    fontSize: "12px !important",
    textTransform: "none",
    fontWeight: "500 !important",
  },
  styleDialog: {
    "&..MuiDialog-root .MuiDialog-container .MuiPaper-root": {
      padding: "20px !important",
    },
  },
}));

export default emailStyle;
