import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Loader from "../../../../components/loader";
import NavigationDrawer from "../../../../components/navigationDrawer";
import SnackbarAlert from "../../../../components/snackbar/SnackbarAlert";
import { getAllCourses } from "../../../../services/admin/courses/Courses";
import {
  getAllBatchesByCourseId,
  getAllStudentsByBatchId,
} from "../../../../services/members/batch/Batch";
import { fetchReportById } from "./../../../../services/admin/report/Reposts";
import ReportCards from "./reportCard";
import dashboardStyles from "./style";

const Report = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const classes = dashboardStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [allCourses, setAllCourses] = useState([]);
  const [allBatches, setAllBatches] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState("");
  const [reportsData, setReportsData] = useState(null);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  console.log(reportsData, "REPORT_DAATAAT");

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  useEffect(() => {
    getAllCourses(user?._id)
      .then((res) => {
        console.log(res, "ressssss");
        let data = res.data.data ? res.data.data : {};
        setAllCourses(data);
      })
      .catch((err) => {
        console.log(err, "errr");
      });
    // sortData();
  }, []);

  const handleOnChangeCourse = (e) => {
    setSelectedCourse(e.target.value);
    setSelectedBatch("");
    getAllBatchesByCourseId(e.target.value)
      .then((res) => {
        console.log(res, "ressssssdsfdsfrfd");
        let data = res.data.data ? res.data.data : {};
        setAllBatches(data);
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  };
  const handleOnChangeBatch = (e) => {
    setSelectedBatch(e.target.value);
  };

  const handleGenerateReport = () => {
    if (selectedCourse == "") {
      handleSnackbarVal(true, "error", "Please select course");
    } else if (selectedBatch == "") {
      handleSnackbarVal(true, "error", "Please select batch");
    } else {
      let payload = {
        courseId: selectedCourse,
        batchId: selectedBatch,
      };
      setIsLoading(true);
      fetchReportById(payload)
        .then((res) => {
          getAllStudentsByBatchId(res.data.data._id)
            .then((response) => {
              setIsLoading(false);
              let overAllFee = 0;
              let feesPaid = 0;
              let temArr = [];
              let allStudentsData = [];

              let confirmStudents = response.data.data.filter(
                (anObject) => anObject.isConfirmed === true
              );

              confirmStudents.map((iteemmmm) => {
                allStudentsData.push({
                  ...iteemmmm,
                  totalFees: res.data.data.totalFees,
                });
              });

              allStudentsData.map((data) => {
                overAllFee += data.totalFees;
                feesPaid += data.feesPaid;
              });

              // response.data.data.map((item) => {
              //   if (item.isConfirmed){
              //     temArr.push({item})
              //   }
              // });

              // console.log(temArr, "xsdfdfsddewfsfewfsEFFWFAE");

              // create new obj for calculation
              let reportObj = {
                totalReceivedAmount: feesPaid,
                teacherShareAmount:
                  (overAllFee * res.data.data.teacherShare) / 100,
                ipdShareAmount:
                  (overAllFee * res.data.data.instituteShare) / 100,
                teacherPercentage: res.data.data.teacherShare,
                ipdPercentage: res.data.data.instituteShare,
                totalFees: res.data.data.totalFees,
              };
              setReportsData(reportObj);
              console.log(reportObj, "DATAATATAA");
            })
            .catch(() => {});
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  return (
    <NavigationDrawer>
      <Loader isloading={isLoading} />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Box sx={{ marginBottom: "25px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <InputLabel>Select Course</InputLabel>
            <FormControl fullWidth size="small">
              <Select
                value={selectedCourse}
                displayEmpty
                onChange={handleOnChangeCourse}
              >
                {allCourses.map((item) => {
                  return <MenuItem value={item._id}>{item.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputLabel>Select Batch</InputLabel>
            <FormControl fullWidth size="small">
              <Select value={selectedBatch} onChange={handleOnChangeBatch}>
                {allBatches.map((item) => {
                  return <MenuItem value={item._id}>{item.batchName}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              variant="contained"
              className={classes.exportBtn}
              onClick={handleGenerateReport}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Box>

      <ReportCards
        totalReceivedAmount={reportsData?.totalReceivedAmount}
        teacherShare={reportsData?.teacherShareAmount}
        ipdShare={reportsData?.ipdShareAmount}
        totalFees={reportsData?.totalFees}
        teacherSharePer={reportsData?.teacherPercentage}
        ipdSharePer={reportsData?.ipdPercentage}
      />
    </NavigationDrawer>
  );
};

export default Report;
