import axios from "axios";
import Swal from "sweetalert2";

// export let baseUrl = "http://192.168.0.122:4000/api/";
export const baseUrl = "https://akeb-ipd.pk/ipd_management/api/";

const api = async (path, params, method) => {
  const token = localStorage.getItem("user_token");
  let options;
  options = {
    headers: {
      "Content-Type": "application/json",
      token,
    },
    method: method,
    ...(params && { data: JSON.stringify(params) }),
  };
  return axios(baseUrl + path, options)
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      if (error.response.status == 401) {
        Swal.fire({
          title: "Your session has expired",
          confirmButtonText: "Ok",
        }).then((result) => {
          localStorage.setItem("user", JSON.stringify(null));
          window.location.reload();
        });
      } else {
        return error.response;
      }
    });
};

export default api;
