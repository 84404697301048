import { makeStyles } from "@mui/styles";
import { primaryColor, secondaryColor } from "../../../../constants/colors";

const memberDashBoardStyle = makeStyles((theme) => ({
  profileCard: {
    padding: "20px",
    boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
    borderRadius: "10px",
    borderBottom: `4px solid ${primaryColor}`,
  },
  customCard: {
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow: "none",
    backgroundImage: "none",
    borderRadius: "12px",
    // border: "none rgba(144, 202, 249, 0.46",
    color: "rgb(255, 255, 255)",
    overflow: "hidden",
    position: "relative",
  },
  avatarStyle: {
    background: "rgb(179, 157, 219) !important",
    cursor: "pointer !important",
    width: "22px !important",
    height: "22px !important",
    marginLeft: 10,
    fontSize: "1rem !important",
    color: "rgb(94, 53, 177) !important",
  },
  count: {
    margin: "14px 8px 6px 0px !important",
    lineHeight: "1.334em !important",
    fontFamily: "Roboto, sans-serif !important",
    fontSize: "2.125rem !important",
    fontWeight: 500,
  },
  title: {
    margin: "0px",
    lineHeight: "1.334em",
    fontFamily: "Roboto, sans-serif",
    color: "rgb(179, 157, 219)",
    fontSize: "1rem",
    fontWeight: 600,
  },
}));

export default memberDashBoardStyle;
