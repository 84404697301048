import Api from '../../index';
import { endPoints, requestType } from '../../../constants/variables';

export const fetchAllMembers = (id) => {
  return Api(`${endPoints.fetchMember}`, null, requestType.GET);
};

export const updateMemberStatus = (params) => {
  return Api(endPoints.updateMemberStatus, params, requestType.POST);
};

