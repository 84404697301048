import React, { useState, useEffect } from "react";
import { DialogContent, TextField, InputLabel, Grid } from "@mui/material";
import useStyles from "../../../../globalStyles";
import BootstrapDialog from "../../../../components/dialog";
import DialogHeader from "../../../../components/dialog/dialogHeader";
import DialogFooter from "../../../../components/dialog/dialogFooter";
import {
  createCourse,
  updateCourseById,
} from "../../../../services/admin/courses/Courses";

export default function CoursesDialog({
  open,
  type,
  inputValue,
  setInputValue,
  handleClose,
  getCategories,
  dialogData,
  setIsLoading,
  handleSnackbarVal,
}) {
  const classes = useStyles();

  useEffect(() => {
    if (dialogData) {
      setInputValue({
        name: dialogData?.name,
        fees: dialogData?.fees,
        courseType: dialogData?.courseType,
      });
    }
  }, [dialogData]);

  const handleChangeInput = (e) => {
    setInputValue({
      ...inputValue,
      [e.target.name]: e.target.value,
    });
  };

  console.log(inputValue, "Sasdasdsdsdsa");
  const handleBtn = async () => {
    if (inputValue.name == "") {
      handleSnackbarVal(true, "error", `Course name is required`);
    } else if (inputValue.fees == "") {
      handleSnackbarVal(true, "error", `Corse fees is required`);
    } else if (inputValue.courseType == "") {
      handleSnackbarVal(true, "error", `Corse Type is required`);
    } else {
      if (type === "add") {
        let payload = {
          name: inputValue.name,
          fees: inputValue.fees,
          courseType: inputValue?.courseType,
        };
        createCourse(payload)
          .then((res) => {
            if (res.status == 200) {
              setIsLoading(false);
              handleSnackbarVal(true, "success", res.data.message);
              getCategories();
              handleClose();
            } else {
              handleSnackbarVal(true, "error", res.data.message);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            handleClose();
            console.log(error, "asdasdsada");
            setIsLoading(false);
            handleSnackbarVal(true, "error", error?.response?.data?.message);
          });
      } else {
        let payload = {
          name: inputValue.name,
          fees: inputValue.fees,
          courseType: inputValue?.courseType,
        };
        setIsLoading(true);
        console.log(dialogData, "dialogDatadialogData");
        updateCourseById(dialogData._id, payload)
          .then((response) => {
            if (response.status == 200) {
              handleSnackbarVal(true, "success", response.data.message);
              setIsLoading(false);
              handleClose();
              getCategories();
            } else {
              setIsLoading(false);
              handleSnackbarVal(true, "error", response?.data?.message);
            }
          })
          .catch((error) => {
            handleSnackbarVal(true, "error", error?.response?.data?.message);
            setIsLoading(false);
            handleClose();
          });
      }
    }
  };

  return (
    <div>
      <BootstrapDialog onClose={handleClose} open={open}>
        <DialogHeader
          title={type == "add" ? "Add Course" : "Edit Course"}
          handleClose={handleClose}
        />
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Course Name
              </InputLabel>
              <TextField
                autoFocus
                placeholder="Name"
                variant="outlined"
                size="small"
                name="name"
                className={classes.globalInput}
                value={inputValue.name}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>Fees</InputLabel>
              <TextField
                placeholder="Fees"
                variant="outlined"
                size="small"
                type="number"
                name="fees"
                className={classes.globalInput}
                value={inputValue.fees}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Course Type
              </InputLabel>
              <select
                value={inputValue?.courseType}
                onChange={handleChangeInput}
                name="courseType"
                className="select-option-style"
                style={{ width: "100%", padding: "11px" }}
              >
                <option value="">Select Course Type</option>
                <option value="Course">Course</option>;
                <option value="Workshop">Workshop</option>;
              </select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogFooter
          type={type}
          handleCreate={handleBtn}
          handleUpdate={handleBtn}
          handleClose={handleClose}
        />
      </BootstrapDialog>
    </div>
  );
}
