import { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Container,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import DynamicTable from "../../../../components/dynamicTable";
import NavigationDrawer from "../../../../components/navigationDrawer";
import BatchAddEditDialog from "./batchAddEditDialog";
import DeleteHelper from "./../../../../components/alert/deleteHelper";
import Loader from "../../../../components/loader";
import SnackbarAlert from "../../../../components/snackbar/SnackbarAlert";
import { useNavigate } from "react-router-dom";
import {
  deleteBatch,
  getAllBatches,
  updateBatchStatusById,
} from "../../../../services/members/batch/Batch";
import BatchStyle from "./style";

const headerData = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "batchName",
    numeric: false,
    disablePadding: true,
    label: "Batch Name",
  },
  {
    id: "totalFees",
    numeric: false,
    disablePadding: true,
    label: "Total Fees",
  },
  {
    id: "teacherShare",
    numeric: false,
    disablePadding: true,
    label: "Teacher Share",
  },
  {
    id: "instituteShare",
    numeric: false,
    disablePadding: true,
    label: "Institute Share",
  },
  {
    id: "startDate",
    numeric: false,
    disablePadding: true,
    label: "Start Date",
  },
  {
    id: "endDate",
    numeric: false,
    disablePadding: true,
    label: "End Date",
  },
  {
    id: "batchStatus",
    numeric: false,
    disablePadding: true,
    label: "Batch Status",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];

const diplayRows = [
  "id",
  "batchName",
  "totalFees",
  "teacherShare",
  "instituteShare",
  "startDate",
  "endDate",
  "statusDropDown",
  "actions",
];

const Batch = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const classes = BatchStyle();
  const [type, setType] = useState("add");
  const [openDialog, setOpenDialog] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterByCourse, setFilterByCourse] = useState([]);
  const [allCourse, setAllCourse] = useState("All");
  const [dialogData, setDialgData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState({
    totalFees: "",
    teacherShare: "",
    instituteShare: "",
    startDate: new Date(),
    endDate: new Date(),
    batchName: "",
    courseId: "",
    courseName: "",
    teacherName: "",
    assignedMember: [],
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  useEffect(() => {
    // let data = JSON.parse(localStorage.getItem("batchSearch"));
    // console.log(data, "Ssadasasa");
    // if (data == "All" || !data) {
    //   setAllCourse("All");
    //   getAllBatchesByMember("All");
    //   // handleFilterById({ target: { value: "All" } });
    // } else {
    //   setAllCourse(data);
    //   // handleFilterById({ target: { value: data } });
    // }
    getAllBatchesByMember();
  }, []);

  const getAllBatchesByMember = (data) => {
    setIsLoading(true);
    let tempArr = [];
    getAllBatches()
      .then((res) => {
        console.log(res, "resresresresresres");
        res.data.data.map((item) => {
          tempArr.push({
            ...item.batch,
            courseName: item.courseName,
          });
        });
        setIsLoading(false);
        setRowsData(tempArr);
        setFilterData(tempArr);
        // console.log(data, "datadatadata");
        // handleFilterById({ target: { value: data } });
      })
      .catch((err) => {
        console.log(err, "errr");
        setIsLoading(false);
      });
  };

  const openAddDialog = () => {
    setOpenDialog(true);
    setType("add");
  };

  const openEditDialog = (row) => {
    setOpenDialog(true);
    setDialgData(row);
    setType("edit");
  };

  const handleClose = () => {
    setOpenDialog(false);
    setDialgData(null);
    setInputValue({
      totalFees: "",
      teacherShare: "",
      instituteShare: "",
      startDate: new Date(),
      endDate: new Date(),
      teacherName: "",
      assignedMember: [],
    });
  };

  const handleOnfilter = (event) => {
    if (allCourse == "All") {
      const filterItem = rowsData.filter((item) => {
        return item.batchName
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
      setFilterData(filterItem);
    } else {
      if (event.target.value == "") {
        const filterItem = rowsData.filter((item) => {
          return item.courseId === allCourse;
        });
        setFilterData(filterItem);
      } else {
        const filterItem = filterData.filter((item) => {
          return item.batchName
            .toLowerCase()
            .includes(event.target.value.toLowerCase());
        });
        setFilterData(filterItem);
      }
    }
  };

  const handleFilterById = (event) => {
    let selected = event.target.value;
    // console.log(selected, rowsData, "selected");
    // localStorage.setItem("batchSearch", JSON.stringify(selected));

    setAllCourse(selected);
    if (selected == "All") {
      setFilterData(rowsData);
    } else {
      const filteredItem = rowsData.filter((item) => {
        return item.courseId.includes(selected);
      });
      console.log(filteredItem, "filterItemfilterItemfilterItem");
      setFilterData(filteredItem);
    }
  };

  const handleDelete = (val) => {
    DeleteHelper(true, val._id, deleteBatch, getAllBatchesByMember);
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const navigateToDetail = (row) => {
    navigate(`/studentsDetails/${row._id}`, { state: row });
  };

  const handleEditClassStatus = (e, row) => {
    setIsLoading(true);
    updateBatchStatusById(row._id, { classStatus: e })
      .then((ress) => {
        console.log(ress, "RESPONNNNNN");
        setIsLoading(false);
        if (ress?.status == 200 || ress?.status == 201) {
          getAllBatchesByMember();
          handleSnackbarVal(true, "success", ress?.data?.message);
        } else {
          handleSnackbarVal(true, "error", ress?.data?.message);
        }
      })
      .catch((errr) => {
        console.log(errr, "EROOOR");
        setIsLoading(false);
        handleSnackbarVal(true, "error", errr?.message);
      });
  };

  return (
    <NavigationDrawer>
      <Loader isloading={isLoading} />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />

      <Grid container alignItems="center">
        <Grid item xs={12} md={2}>
          <Typography style={{ fontSize: "22px", fontWeight: "600" }}>
            Batch
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} justifyContent="end">
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={allCourse}
              onChange={handleFilterById}
              size="small"
            >
              <MenuItem value="All">All</MenuItem>
              {filterByCourse.map((item, index) => {
                return (
                  <MenuItem value={item._id} key={index}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            placeholder="Search by Batch "
            size="small"
            onChange={handleOnfilter}
            fullWidth
            // value={}
          />
        </Grid>
      </Grid>
      <div style={{ textAlign: "right", margin: "10px 0" }}>
        {user.role !== "ob" ? (
          <Button
            variant="contained"
            size="small"
            disableRipple
            className={classes.loginBtn}
            onClick={openAddDialog}
          >
            <Typography className={classes.loginBtnText}>Add New</Typography>
          </Button>
        ) : null}
      </div>

      {/* >> Table */}
      <DynamicTable
        headerData={headerData}
        bodyRows={filterData}
        displayData={diplayRows}
        showDelete={user.role == "ob" ? false : true}
        showEdit={user.role == "ob" ? false : true}
        showView={true}
        navigateToDetail={navigateToDetail}
        handleDelete={handleDelete}
        openEditDialog={openEditDialog}
        handleEditClassStatus={handleEditClassStatus}
        type={type}
      />

      {/*>> batch edit dialog*/}
      <BatchAddEditDialog
        open={openDialog}
        setOpenDialog={setOpenDialog}
        type={type}
        inputValue={inputValue}
        setInputValue={setInputValue}
        handleClose={handleClose}
        getCategories={getAllBatchesByMember}
        dialogData={dialogData}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        handleSnackbarVal={handleSnackbarVal}
        setFilterByCourse={setFilterByCourse}
      />
    </NavigationDrawer>
  );
};

export default Batch;
