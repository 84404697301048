import React, { useState, useEffect } from "react";
import { DialogContent, TextField, InputLabel, Grid } from "@mui/material";
import useStyles from "../../../../globalStyles";
import BootstrapDialog from "../../../../components/dialog";
import DialogHeader from "../../../../components/dialog/dialogHeader";
import DialogFooter from "../../../../components/dialog/dialogFooter";
import {
  registerOb,
  updateOb,
} from "../../../../services/ob/obAuthentication/obAuthentication";

export default function ObAddDialog({
  open,
  type,
  inputValue,
  setInputValue,
  handleClose,
  getAllMembers,
  dialogData,
  setIsLoading,
  handleSnackbarVal,
}) {
  const classes = useStyles();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    if (dialogData) {
      setInputValue({
        name: dialogData?.name,
        email: dialogData?.email,
      });
    }
  }, [dialogData]);

  const handleChangeInput = (e) => {
    setInputValue({
      ...inputValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleBtn = async () => {
    console.log(inputValue, "dialogDatadialogDatadialogData");
    if (
      inputValue.name == "" ||
      inputValue.email == "" ||
      inputValue.password == ""
    ) {
      handleSnackbarVal(true, "error", `All Field are required`);
    } else if (!emailRegex.test(inputValue.email)) {
      handleSnackbarVal(true, "error", `Invalid Email Format`);
    } else {
      if (type === "add") {
        let payload = {
          name: inputValue.name,
          password: inputValue.password,
          email: inputValue.email,
        };
        setIsLoading(true);
        registerOb(payload)
          .then((res) => {
            if (res?.status == 200) {
              setInputValue({
                name: "",
                email: "",
                password: "",
              });
              setIsLoading(false);
              handleSnackbarVal(true, "success", res.data.message);
              getAllMembers();
              handleClose();
            } else {
              setIsLoading(false);
              handleSnackbarVal(true, "error", res?.data?.message);
            }
          })
          .catch((error) => {
            handleClose();
            console.log(error, "asdasdsada");
            setIsLoading(false);
            handleSnackbarVal(true, "error", error?.response?.data?.message);
          });
      } else {
        let payload = {
          name: inputValue.name,
          password: inputValue.password,
        };
        setIsLoading(true);
        updateOb(dialogData._id, payload)
          .then((response) => {
            if (response?.status == 200) {
              setInputValue({
                name: "",
                password: "",
              });
              setIsLoading(false);
              handleClose();
              getAllMembers();
            } else {
              setIsLoading(false);
              handleSnackbarVal(true, "error", response?.data?.message);
            }
          })
          .catch((error) => {
            handleSnackbarVal(true, "error", error?.response?.data?.message);
            setIsLoading(false);
            handleClose();
          });
      }
    }
  };

  return (
    <div>
      <BootstrapDialog onClose={handleClose} open={open}>
        <DialogHeader
          title={type == "add" ? "Add OB" : "Edit OB"}
          handleClose={handleClose}
        />
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                OB Name
              </InputLabel>
              <TextField
                autoFocus
                placeholder="OB Name"
                variant="outlined"
                size="small"
                name="name"
                className={classes.globalInput}
                value={inputValue.name}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Email
              </InputLabel>
              <TextField
                placeholder="Email"
                variant="outlined"
                size="small"
                name="email"
                className={classes.globalInput}
                value={inputValue.email}
                onChange={handleChangeInput}
              />
            </Grid>
            {type !== "edit" && (
              <Grid item xs={12} sm={6}>
                <InputLabel className={classes.globalInputLabel}>
                  Password
                </InputLabel>
                <TextField
                  placeholder="Password"
                  variant="outlined"
                  size="small"
                  name="password"
                  className={classes.globalInput}
                  value={inputValue.password}
                  onChange={handleChangeInput}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogFooter
          type={type}
          handleCreate={handleBtn}
          handleUpdate={handleBtn}
          handleClose={handleClose}
        />
      </BootstrapDialog>
    </div>
  );
}
