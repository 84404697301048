import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DynamicTable from "../../../../components/dynamicTable";
import Loader from "../../../../components/loader";
import SnackbarAlert from "../../../../components/snackbar/SnackbarAlert";
import { getOnlineStudent } from "../../../../services/admin/students/student";
import NavigationDrawer from "./../../../../components/navigationDrawer/index";

const headerData = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "age",
    numeric: false,
    disablePadding: true,
    label: "Age",
  },
  {
    id: "contact",
    numeric: false,
    disablePadding: true,
    label: "Contact",
  },
  {
    id: "cnic",
    numeric: false,
    disablePadding: true,
    label: "Cnic",
  },
  {
    id: "jamatKhana",
    numeric: false,
    disablePadding: true,
    label: "Jamat Khana",
  },
  {
    id: "localCouncil",
    numeric: false,
    disablePadding: true,
    label: "Local Council",
  },
  {
    id: "qualification",
    numeric: false,
    disablePadding: true,
    label: "Qualification",
  },
  {
    id: "school",
    numeric: false,
    disablePadding: true,
    label: "School/University",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: true,
    label: "Address",
  },
  {
    id: "computerSkill",
    numeric: false,
    disablePadding: true,
    label: "Computer Skill",
  },
  {
    id: "infoSource",
    numeric: false,
    disablePadding: true,
    label: "Info Source",
  },
  {
    id: "courseName",
    numeric: false,
    disablePadding: true,
    label: "Course",
  },
];

const diplayRows = [
  "id",
  "name",
  "email",
  "age",
  "contact",
  "cnicNo",
  "jamatKhana",
  "localCouncil",
  "qualification",
  "school",
  "address",
  "computerSkill",
  "infoSourse",
  "courseName",
];

function OnlineRegisteredAllFields() {
  const [allStudents, setAllStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  useEffect(() => {
    getAllStudentsData();
  }, []);

  const getAllStudentsData = () => {
    setIsLoading(true);
    getOnlineStudent()
      .then((res) => {
        let data = res.data.data.reverse();
        setIsLoading(false);
        setAllStudents(data);
      })
      .catch((err) => {
        console.log(err, "errr");
        setIsLoading(false);
      });
  };

  return (
    <NavigationDrawer>
      <Loader isloading={isLoading} />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Typography
        style={{
          fontSize: "22px",
          fontWeight: "600",
          marginBottom: "10px",
        }}
      >
        Online Registered Students All Data
      </Typography>
      <Box mt={3}>
        <DynamicTable
          headerData={headerData}
          bodyRows={allStudents}
          displayData={diplayRows}
        />
      </Box>
    </NavigationDrawer>
  );
}

export default OnlineRegisteredAllFields;
