import React from "react";

//Member Screens
import MemberDashBoard from "../../containers/app/memberScreeens/memberDashboard";
import AssignedBatches from "../../containers/app/memberScreeens/assignedBatches/index";
import StudentRegisteredByMember from "../../containers/app/memberScreeens/studentRegisteredByMember/index";

// Admin Screens
import AdminDashboard from "../../containers/app/adminScreens/adminDashboard";
import StudentsRegistrations from "../../containers/app/adminScreens/studentsRegistrations/index";
import Courses from "./../../containers/app/adminScreens/courses/index";
import AdminMembersRegistrations from "../../containers/app/adminScreens/membersRegistrations/index";
import SendEmail from "../../containers/app/adminScreens/sendEmail/index";
import Report from "../../containers/app/adminScreens/report";
import Batch from "./../../containers/app/adminScreens/batch/index";
import SendRequest from "../../containers/app/adminScreens/sendRequest/index";
import ObRegistration from "../../containers/app/adminScreens/obRegistrations";
import OnlineStuednt from "../../containers/app/adminScreens/onlineStudents";
import OnlineRegisteredAllFields from "../../containers/app/adminScreens/onlineStudentsAllFields";

const AdminRoutes = [
  {
    id: 1,
    name: "Dashboard",
    component: <AdminDashboard />,
    exact: "exact",
    path: "",
    icon: "dashboard",
  },
  {
    id: 2,
    name: "Students Registrations",
    component: <StudentsRegistrations />,
    exact: "exact",
    path: "studentsRegistrations",
    icon: "accessibility",
  },
  {
    id: 3,
    name: "Members Registrations",
    component: <AdminMembersRegistrations />,
    exact: "exact",
    path: "membersRegistration",
    icon: "group",
  },
  {
    id: 4,
    name: "OB Registrations",
    component: <ObRegistration />,
    exact: "exact",
    path: "obRegistrations",
    icon: "group",
  },
  {
    id: 5,
    name: "Courses",
    component: <Courses />,
    exact: "exact",
    path: "courses",
    icon: "local_library",
  },
  {
    id: 6,
    name: "Send Email",
    component: <SendEmail />,
    exact: "exact",
    path: "sendEmail",
    icon: "forward_to_inbox",
  },
  // {
  //   id: 5,
  //   name: 'Send Request',
  //   component: <SendRequest />,
  //   exact: 'exact',
  //   path: 'sendRequest',
  //   icon: 'forward_to_inbox',
  // },
  {
    id: 7,
    name: "Report",
    component: <Report />,
    exact: "exact",
    path: "report",
    icon: "report",
  },
  {
    id: 8,
    name: "Batch",
    component: <Batch />,
    exact: "exact",
    path: "batch",
    icon: "batch_prediction",
  },
  {
    id: 9,
    name: "Online Students",
    component: <OnlineStuednt />,
    exact: "exact",
    path: "onlineStudent",
    icon: "batch_prediction",
  },
  // {
  //   id: 10,
  //   name: "Online Studemt All Data",
  //   component: <OnlineRegisteredAllFields />,
  //   exact: "exact",
  //   path: "onlineStudentAllFields",
  //   icon: "batch_prediction",
  // },
];

const MemberRoutes = [
  {
    id: 1,
    name: "Dashboard",
    component: <MemberDashBoard />,
    exact: "exact",
    path: "",
    icon: "dashboard",
  },
  {
    id: 2,
    name: "Registrations",
    component: <StudentRegisteredByMember />,
    exact: "exact",
    path: "registrations",
    icon: "app_registration",
  },
  {
    id: 3,
    name: "Assigned Batch",
    component: <AssignedBatches />,
    exact: "exact",
    path: "batch",
    icon: "batch_prediction",
  },
  {
    id: 4,
    name: "Online Students",
    component: <OnlineStuednt />,
    exact: "exact",
    path: "onlineStudent",
    icon: "batch_prediction",
  },
];

const ObRoutes = [
  {
    id: 1,
    name: "Courses",
    component: <Courses />,
    exact: "exact",
    path: "",
    icon: "local_library",
  },
  {
    id: 2,
    name: "Report",
    component: <Report />,
    exact: "exact",
    path: "report",
    icon: "report",
  },
  {
    id: 3,
    name: "Batch",
    component: <Batch />,
    exact: "exact",
    path: "batch",
    icon: "batch_prediction",
  },
  {
    id: 4,
    name: "Online Students",
    component: <OnlineStuednt />,
    exact: "exact",
    path: "onlineStudent",
    icon: "batch_prediction",
  },
];

export { AdminRoutes, MemberRoutes, ObRoutes };
